const locationType = {
  Home: 'Home',
  Facility: 'Facility',
};

const locationTypeId = {
  Home: '6672b15ab78c242eb96f7541',
  Facility: '6672b15ab78c242eb96f7543',
};

export default {locationType, locationTypeId};
