import React from 'react';
import {Col, Row} from '@unthinkable/react-core-components';
import {
  LeftNavBoxTitle,
  MainContainer,
  MiddleNavBoxTitle,
  NavBoxMessage,
  RightNavBoxTitle,
  StyledSeparator,
} from './PatientHeader.style';
import {Icon} from '../../../components/filePreviewer/Filepreviewer.style';
import images from '../../../assets/images';
import {getRemainingDaysMessage, getVariant} from '../../../utils/commonMethod';

const LeftBox = ({title, message, variant = 'normal', icon}) => {
  return (
    <Row>
      <Col gap={4}>
        <LeftNavBoxTitle variant={variant}>
          {icon && <Icon source={icon} />}
          {title}
        </LeftNavBoxTitle>
        <NavBoxMessage>{message}</NavBoxMessage>
      </Col>
      <StyledSeparator variant={variant} />
    </Row>
  );
};

const MiddleBox = ({title, message = '', variant = 'normal', icon}) => {
  return (
    <Row>
      <Col gap={4}>
        <MiddleNavBoxTitle variant={variant}>
          {icon && <Icon source={icon} />}
          {title}
        </MiddleNavBoxTitle>
        <NavBoxMessage>{message}</NavBoxMessage>
      </Col>
      <StyledSeparator variant={variant} />
    </Row>
  );
};

const RightBox = ({title, message, variant, icon}) => {
  return (
    <Col gap={4}>
      <RightNavBoxTitle variant={variant}>
        {icon && <Icon source={icon} />}
        {title}
      </RightNavBoxTitle>
      <NavBoxMessage>{message}</NavBoxMessage>
    </Col>
  );
};

export const TaskWidget = ({encounter, skipMessage = false}) => {
  const taskDetails = encounter?.taskInfo;
  const encounterDetail = encounter;
  const typeWiseTaskInfo = {};
  taskDetails?.forEach(task => (typeWiseTaskInfo[task.type] = task));
  if (
    encounterDetail &&
    (typeWiseTaskInfo?.ContactTask ||
      typeWiseTaskInfo?.MedicationTask ||
      typeWiseTaskInfo?.VisitTask ||
      typeWiseTaskInfo?.NotesTask)
  ) {
    const contactVariant = getVariant(
      typeWiseTaskInfo?.ContactTask,
      encounterDetail,
    );
    return (
      <MainContainer>
        <RightBox
          title={'Notes'}
          message={
            !skipMessage && getRemainingDaysMessage(typeWiseTaskInfo?.NotesTask)
          }
          icon={images.notes}
          variant={getVariant(typeWiseTaskInfo?.NotesTask, encounterDetail)}
        />
        {typeWiseTaskInfo?.Form1823 && (
          <MiddleBox
            title={'Form1823'}
            icon={images.form1823}
            variant={getVariant(typeWiseTaskInfo?.Form1823, encounterDetail)}
          />
        )}
        <MiddleBox
          title={'Visit'}
          message={
            !skipMessage && getRemainingDaysMessage(typeWiseTaskInfo?.VisitTask)
          }
          icon={images.visit}
          variant={getVariant(typeWiseTaskInfo?.VisitTask, encounterDetail)}
        />
        <MiddleBox
          title={'Med Rec'}
          message={
            !skipMessage &&
            getRemainingDaysMessage(typeWiseTaskInfo?.MedicationTask)
          }
          icon={images.medRec}
          variant={getVariant(
            typeWiseTaskInfo?.MedicationTask,
            encounterDetail,
          )}
        />
        <LeftBox
          title={'Contact'}
          message={
            !skipMessage &&
            getRemainingDaysMessage(typeWiseTaskInfo?.ContactTask)
          }
          icon={
            contactVariant == 'success' ? images.contactSuccess : images.contact
          }
          variant={contactVariant}
        />
      </MainContainer>
    );
  }
};
