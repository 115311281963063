import React from 'react';
import images from '../../../assets/images';
import {toasterMessage} from '../../../constant';
import {action, entity} from '../../../constant/authorization';
import {TASK_TYPE} from '../../../constant/taskType';
import {STATUS} from '../../../modules/user/constants/UserConstants';
import checkAuthorization from '../../../services/accessService';
import {Icon} from '../../../components/icon';

export const RenderGenerateNote = ({
  row,
  route,
  state = {},
  navigate,
  user,
  toast,
}) => {
  const activeTasks = row?.encounterId?.taskInfo?.filter(task => {
    return task.status === STATUS.ACTIVE && task.type !== TASK_TYPE.NotesTask;
  });
  const showGenerateNote = activeTasks?.length === 0;
  if (checkAuthorization(user, entity.TcmTasks, action.complete)) {
    if (row.type == TASK_TYPE.NotesTask) {
      return (
        <Icon
          src={images.notes}
          onClick={e => {
            if (showGenerateNote) {
              navigate(route, {
                state: {
                  titleName: row.patientId.FullName,
                  patient: row.patientId,
                  encounter: row.encounterId,
                  taskId: row._id,
                  ...state,
                },
              });
            } else {
              toast({
                message: toasterMessage.tcmTaskNotCompleted.message,
                type: 'Error',
              });
            }
            e.stopPropagation();
          }}
        />
      );
    }
  }
};
