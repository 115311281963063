import styled from 'styled-components';
import theme from 'src/theme';
const {colors} = theme.base;

export const EncounterTaskStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    borderTop: '1px solid #e4e4e7',
    borderBottom: '1px solid #e4e4e7',
    gap: '12px',
  },
  heading: {
    fontSize: '16px',
    fontFamily: 'Inter-SemiBold',
    lineHeight: '20px',
    color: '#131313',
  },
  taskContainer: {
    fontSize: '14px',
  },
  taskRow: {
    fontSize: '14px',
    alignItems: 'center',
  },
  dueDateText: {
    fontSize: '12px',
  },
  Icon: {
    height: '14px',
    width: '14px',
  },
};
export const ActiveTaskRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
  color: ${({variant}) => variantWiseTextColors[variant]};
  gap: 4px;
  cursor: pointer;
`;

export const patientInfoStyle = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid #e4e4e7',
    maxWidth: '300px',
  },
  patientInfoContainer: {
    minWidth: '250px',
    padding: '12px',
    gap: '10px',
  },
  patientInfoHeader: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Avatar: {
    height: '88px',
    width: '88px',
  },
  IconWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '35px',
    width: '35px',
    borderRadius: '6px',
    padding: '10px',
    backgroundColor: '#161E2D',
    cursor: 'pointer',
  },
  locationContainer: {
    marginTop: '6px',
    alignItems: 'center',
    gap: 6,
  },
  patientNameContainer: {
    gap: '16px',
    justifyContent: 'space-between',
  },
  heading1: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '27px',
    color: colors.NEUTRAL_HIGH,
  },
  heading2: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#52525B',
  },
};

export const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #e4e4e7;
  @media (min-width: 425px) {
    padding: 0px;
    border-left: 1px solid #e4e4e7;
  }
  @media (min-width: 768px) {
    border-left: 1px solid #e4e4e7;
    max-width: 250px;
  }
  @media (min-width: 1024px) {
    max-width: 280px;
  }
`;

export const Icon = styled.img`
  height: 18px;
  width: 18px;
  cursor: pointer;
  ${({styles}) => styles}
`;

export const variantWiseTextColors = {
  normal: theme.base.colors.NEUTRAL_HIGH,
  warning: theme.base.colors.PATIENT_NAV_HIGH,
  success: theme.base.colors.SUCCESS_HIGH,
  alarm: theme.base.colors.RED_TCM_FORE_COLOR,
};

export const ButtonContainer = styled.div`
  padding: 10px;
`;
