import React, {useEffect, useState, useRef} from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {routeLink} from '../../../constant';
import {action, entity} from '../../../constant/authorization';
import {employerType} from '../../../constant/employerType';
import {status} from '../../../constant/status';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {HttpAuthService} from '../../../services';
import checkAuthorization from '../../../services/accessService';
import {
  ChartContainer,
  PracticeStatusChartStyle,
  LoaderContainer,
} from '../styles/Dashboard.style'; // Ensure LoaderContainer is styled
import {TableHeader} from '../../../components/header/TableHeader';
import Config from '../../../Config';
import {
  isFacilityEmployee,
  isPracticeEmployee,
} from '../../../utils/checkEmployerType';
import {isAdmin} from '../../../utils/checkUserType';

const PracticeStatusChart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const scrollContainerRef = useRef(null);
  const {user} = useAuth();

  const filter = {
    isRedoxFacility: false,
    status: status.active,
  };

  if (user?.employerType?.name === employerType.facility) {
    filter['_id'] = user?.facilityId?._id;
  }
  if (user?.employerType?.name === employerType.practice) {
    if (checkAuthorization(user, entity.facilityStats, action.view)) {
      filter['practices'] = user?.practiceId?._id;
    } else if (checkAuthorization(user, entity.facilityStats, action.viewOwn)) {
      filter['_id'] = {$in: user?.facilityAssigned};
    }
  }

  const fetchFacilityWithStats = async currentPage => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await HttpAuthService.get(routeLink.getFacilitiesStats, {
        params: {
          filter: JSON.stringify(filter),
          skip: currentPage * Config.itemsPerPageGraph,
          limit: Config.itemsPerPageGraph,
          sort: JSON.stringify({name: 1}),
        },
      });

      if (response.data) {
        setData(prevData => [...prevData, ...response.data]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFacilityWithStats(page);
  }, [page]);

  const handleScroll = () => {
    let prevPage = data.length / Config.itemsPerPageGraph - 1;
    if (prevPage < 0) {
      prevPage = 0;
    }
    const container = scrollContainerRef.current;
    if (container) {
      const {scrollLeft, scrollWidth, clientWidth} = container;
      if (scrollLeft + clientWidth >= scrollWidth - 50 && !loading) {
        setPage(() => prevPage + 1);
      }
    }
  };

  const chartWidth = Math.max(data.length * 150, window.innerWidth * 0.5);
  const CustomXAxisTick = ({x, y, payload, maxChars = 12}) => {
    const formatText = text =>
      text.length > maxChars ? text.slice(0, maxChars) + '..' : text;
    return (
      <text x={x} y={y} textAnchor="middle" fill="#666" dy={10}>
        {formatText(payload.value)}
      </text>
    );
  };

  return (
    <ChartContainer>
      <TableHeader
        title={isFacilityEmployee() ? 'Facility Status' : 'Practice Status'}
      />
      <PracticeStatusChartStyle
        ref={scrollContainerRef}
        onScroll={handleScroll}>
        {/* Render the chart */}
        <BarChart
          width={chartWidth}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          barSize={20}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={<CustomXAxisTick />}
            angle={-45}
            textAnchor="end"
          />
          <YAxis />
          {data.length > 0 && <Tooltip />}
          <Legend
            align="left"
            verticalAlign="bottom"
            layout="horizontal"
            wrapperStyle={{padding: '20px', marginLeft: '60px'}}
          />
          <Bar
            dataKey="totalReAdmissionEncounter"
            name="Readmissions"
            stackId="a"
            fill="#4ecdc4"
          />
          {(isPracticeEmployee() || isAdmin()) && (
            <Bar
              dataKey="totalBillableEncounter"
              name="Completed Tcm Encounter"
              stackId="a"
              fill="#0074cc"
            />
          )}
          <Bar
            dataKey="currentHospitalization"
            name="Send Outs"
            stackId="a"
            fill="#003d99"
          />
          <Bar
            dataKey="totalPatient"
            name="Total Patients"
            stackId="a"
            fill="#00264d"
          />
        </BarChart>
        {/* Loader overlay */}
        {loading && <LoaderContainer>{<ActivityIndicator />}</LoaderContainer>}
      </PracticeStatusChartStyle>
    </ChartContainer>
  );
};

export default PracticeStatusChart;
