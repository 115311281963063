import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {View} from '@unthinkable/react-core-components';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {useToast} from '@unthinkable/react-toast/index.js';

import {BasicTable} from '../../../components/table/Table.js';
import routeLink from '../../../constant/routeLink.js';
import {status} from '../../../constant/status';
import {
  patientDetailsWidgetStyle,
  patientDetailsWidgetStyleForMobile,
} from '../screens/style/PatientDetail.style';
import {HttpAuthService} from '../../../services';
import {RowHeading} from '../../../components/header/RowHeading';
import {generateCategoryWiseDiagnose} from '../../../services/patientDiagnose.service';
import {Icon} from '../../../components/icon';
import images from '../../../assets/images';
import {invokeEvents} from '../../../hooks/useInvoke';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';
import {TableHeader} from '../../../components/header/TableHeader.js';
import checkAuthorization, {
  checkPrimaryPractice,
} from '../../../services/accessService.js';
import {action, entity} from '../../../constant/authorization.js';
import {Button} from '../../../components/button/Button.js';
import buttonType from '../../../constant/buttonType.js';
import {useAuth} from '../../../modules/auth/hooks/useAuth.js';
import {getPatientById} from '../service/patient.service.js';

export const OutPatientDiagnose = props => {
  const {state} = useLocation();
  const {patientId} = useParams();
  const {patient: statePatient} = state || {};
  const [patient, setPatient] = useState(statePatient);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clinicalSummaryLoading, setClinicalSummaryLoading] = useState(false);

  const {user} = useAuth();
  const toast = useToast();
  const {notify} = useEventHandler();
  const navigate = useNavigate();

  useDataFetchEvents({eventSourceId: 'outpatientDiagnose'}, () => {
    fetchPatientDiagnose();
  });
  const fetchPatientDiagnose = async () => {
    setLoading(true);
    const response = await HttpAuthService.get(
      routeLink.getOutPatientDiagnoses,
      {
        params: {
          filter: {
            patientId: patientId,
            diagnoseApproved: true,
            status: status.active,
          },
          fields: {
            diagnose: {
              Name: 1,
              Code: 1,
              CodeSystemName: 1,
            },
          },
        },
      },
    );
    setData(generateCategoryWiseDiagnose(response.data));
    setLoading(false);
  };

  const fetchClinicalSummaryHandler = async () => {
    setClinicalSummaryLoading(true);
    const response = await HttpAuthService.get(
      `${routeLink.fetchClinicalSummary}`,
      {
        params: {patient: patient},
      },
    );
    if (response.type === 'Success') {
      notify &&
        notify('dataUpdated', {
          id: 'outpatientDiagnose',
        });
      notify &&
        notify('dataUpdated', {
          id: 'outpatient-medications',
        });
    }
    toast({
      message: response.message,
      type: response.type,
    });
    setClinicalSummaryLoading(false);
  };
  const fetchPatient = async () => {
    try {
      if (!patient) {
        const response = await getPatientById(patientId);
        if (response?.data) setPatient(response?.data);
        else {
          navigate('/patient');
        }
      }
    } catch (err) {
      console.log('err', err);
      navigate('/patient');
    }
  };
  useEffect(() => {
    fetchPatient();
    fetchPatientDiagnose();
  }, [patientId]);

  const renderTable = props => {
    const {row} = props;
    const {notify} = useEventHandler();
    const {isMobile} = useMediaQuery();

    if (!row) return;
    if (row?.name === 'Header') {
      return (
        <View
          style={
            isMobile
              ? patientDetailsWidgetStyleForMobile
              : patientDetailsWidgetStyle
          }>
          <BasicTable
            columns={[
              {
                header: 'Code',
                width: 150,
              },
              // {
              //   header: 'Code Name',
              // },
              {
                header: 'Diagnosis',
              },
            ]}></BasicTable>
        </View>
      );
    } else
      return (
        <View style={{flex: 1}}>
          <RowHeading
            title={row.name}
            actions={() => {
              return (
                <Icon
                  src={row.expanded ? images.showLess : images.showMore}
                  onClick={() => {
                    row.expanded = !row.expanded;
                    invokeEvents({
                      eventSourceId: ['collapse'],
                      notify,
                    });
                  }}
                />
              );
            }}
          />

          {row.expanded && (
            <BasicTable
              variant={'outline'}
              data={row.data}
              columns={[
                {
                  field: 'diagnose.Code',
                  type: 'text',
                  width: 150,
                },
                // {
                //   field: 'diagnose.CodeSystemName',
                //   type: 'text',
                // },
                {
                  field: 'diagnose.Name',
                  type: 'text',
                },
              ]}
              {...props}
            />
          )}
        </View>
      );
  };

  return (
    <View style={patientDetailsWidgetStyle}>
      <TableHeader
        title={''}
        actions={[
          ...(checkAuthorization(
            user,
            entity.fetchClinicalSummary,
            action.view,
          ) &&
          checkPrimaryPractice(user, patient) &&
          patient?.status === status.active &&
          patient?.outPatientClinicalSummary !== 'Success'
            ? [
                <Button
                  key="fetchClinicalSummary"
                  text="Fetch Clinical Summary"
                  onPress={fetchClinicalSummaryHandler}
                  loading={clinicalSummaryLoading}
                  buttonType={buttonType.linkButtonWithBorder}
                />,
              ]
            : []),
        ]}
      />
      <BasicTable
        eventSourceId={['collapse']}
        variant={'outline'}
        data={data}
        renderRow={renderTable}
        loading={loading}
        {...props}></BasicTable>
    </View>
  );
};
