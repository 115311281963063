import {useState} from 'react';
import {Keyboard} from '@unthinkable/react-core-components';
import {isMobile} from '@unthinkable/react-utils';
import {getValueToDisplay} from '../Utility';
// import {useFetchSuggestions} from './useFetchSuggestions';
import {useInput} from '@unthinkable/react-input';

export const useAutoComplete = ({
  ref,
  searching = true,
  searchingInModal = isMobile,
  onClearValue: onClearValueProp,
  onSelect: onSelectProp,
  onCreate: onCreateProp,
  onCreateSuggestion: onCreateSuggestionProp,
  getDisplayValue: getDisplayValueFn = getValueToDisplay,
  value,
  onChangeValue,
  onInputBlur = true,
  ...props
}) => {
  const searchingInInput = searching && !searchingInModal;

  const {multiple, suggestionField, options, keyField, valueField} = props;
  if (multiple && value && !Array.isArray(value)) {
    value = [value];
  }

  props = useInput({
    ...props,
    ref,
    value,
    skipActiveLabelOnFocus: !searchingInInput,
  });
  const {inputProps} = props;
  const {onFocus: onFocusProp, onBlur: onBlurProp} = inputProps;

  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState();

  // const {resetCache, fetchSuggestions, ...suggestionProps} =
  //   useFetchSuggestions({
  //     ...props,
  //     onFetchSuggestions: () => {
  //       setShowModal(true);
  //     },
  //   });

  const onChangeSearch = searchText => {
    setSearchValue(searchText);
  };

  const handleFocus = e => {
    onFocusProp?.(e);
    !props.readOnly && setShowModal(true);
    // fetchSuggestions();
  };

  const handleBlur = e => {
    onBlurProp?.(e);
    Keyboard?.dismiss();
    setSearchValue(void 0);
    setShowModal(false);
  };

  const onFocus = e => {
    handleFocus(e);
  };

  const onBlur = e => {
    searchingInInput && handleBlur(e);
  };

  const blurInput = e => {
    !searchingInInput && handleBlur(e);
    props?.inputRef?.current?.blur();
  };

  const onClearValue = e => {
    onChangeValue && onChangeValue(null);
    onClearValueProp && onClearValueProp(e);
  };

  const onSelect = ({item, index}, e) => {
    let newValue = item;
    // removes Options length check to allow use of keyField even in case of api's
    if (item && typeof item === 'object' && keyField) {
      newValue = item[keyField];
    }
    if (multiple) {
      newValue = value ? [...value] : [];
      newValue.push(item);
    }
    onChangeValue?.(newValue);
    onSelectProp && onSelectProp({item, index});
    onInputBlur && blurInput(e);
  };

  const onRemove = ({index}) => {
    if (!value) {
      return;
    }
    if (multiple) {
      let newValue = value.filter((doc, _index) => index !== _index);
      onChangeValue?.(newValue?.length ? newValue : []);
    } else {
      onChangeValue?.(null);
    }
  };

  const onCreate = onCreateProp
    ? ({searchValue, reloadData}, e) => {
        onCreateProp({
          searchValue,
          onChange: newValue => {
            reloadData?.();
            onSelect({item: newValue}, e);
            // resetCache?.();
          },
        });
        blurInput(e);
      }
    : void 0;

  const onCreateSuggestion = onCreateSuggestionProp
    ? async e => {
        let newValue = await onCreateSuggestionProp({
          searchValue,
        });
        onSelect({item: newValue}, e);
        // resetCache?.();
      }
    : void 0;

  const onKeyDown = e => {
    switch (e.key) {
      case 'Backspace':
        if (multiple && !searchValue && Array.isArray(value) && value.length) {
          onRemove({
            index: value.length - 1,
          });
          blurInput(e);
        }
      case 'Tab':
        if (e.keyCode === 9) {
          blurInput(e);
        }
        break;
    }
  };

  const getDisplayValue = ({value}) => {
    return getDisplayValueFn({
      value,
      valueField: valueField || suggestionField,
      keyField,
      options,
    });
  };

  return {
    ...props,
    // ...suggestionProps,
    inputProps: {
      ...inputProps,
      onFocus,
      onBlur,
      onKeyDown,
    },
    onInputBlur,
    searching,
    searchingInModal,
    searchValue,
    showModal,
    value,
    onSelect,
    onRemove,
    onClearValue,
    onChangeSearch,
    onCreate,
    onCreateSuggestion,
    getDisplayValue,
    blurInput,
  };
};
