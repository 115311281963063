import theme from '../../../theme/Theme';
import styled from 'styled-components';
const MessageInputBoxContainer = styled.div`
  display: flex;
  width: 100%;
`;
const MessageInputTextArea = styled.textarea`
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  outline: none;
  resize: none;
  height: 47px;
  border: 1px solid ${theme.colors.NEUTRAL_MEDIUM};
  border-radius: 16px;
`;

const SendMessageDivStyle = {
  padding: '11px 6px',
  position: 'absolute',
  right: 1,
  bottom: 1,
};
export {MessageInputBoxContainer, MessageInputTextArea, SendMessageDivStyle};
