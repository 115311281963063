import styled from 'styled-components';

export const TodayTaskCardStyle = {
  container: {
    borderLeft: '5px solid #ff9d2b',
    backgroundColor: '#fafafa',
    padding: '8px',
    gap: '6px',
  },
  Heading: {
    color: '#1A1A1A',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '21px',
  },
  SubHeading: {
    color: '#52525B',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px',
  },
};

export const Card = styled.div``;

export const LeftBorder = styled.div`
  width: ;
`;
