import React, {useContext, useState} from 'react';
import {LayoutFieldsRender} from '@unthinkable/react-form';
import {useToast} from '@unthinkable/react-toast';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../../components/button';
import {Form} from '../../../components/form';
import buttonType from '../../../constant/buttonType';
import {AuthContext} from '../../../context/auth/AuthContext';
import HttpAuth from '../../../services/HttpAuthService';
import {useAuth} from '../hooks/useAuth';
import {
  BrandName,
  CenteredOverlay,
  ClickableBox,
  FlexContainer,
  FooterContainer,
  FormContainer,
  HeaderContainer,
  LayoutContainer,
  LoginMessage,
  ScreenContainer,
} from './LoginForm.style';
import {onMFASuccess} from '../AuthUtils';
import Config from '../../../Config';

const LoginForm = () => {
  const {setAuth} = useContext(AuthContext);
  const {isSessionTimeOut} = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [isPopupVisible, setIsPopupVisible] = useState(isSessionTimeOut);
  const [loading, setLoading] = useState(false);

  const verifyMFA = async ({user, message, toastType}) => {
    if (user.mfaMode) {
      if (user.mfaMode === 'SMS') {
        navigate('/mfa-sms', {state: {user, message, toastType}});
      } else if (user.mfaMode === 'Authenticator App') {
        navigate('/mfa-authenticator', {state: {user, message, toastType}});
      } else if (user.mfaMode == 'Email') {
        navigate('/mfa-email', {state: {user, message, toastType}});
      }
    } else {
      await onMFASuccess({
        user,
        navigate,
        setAuth: setAuth,
        toast,
        message,
        toastType,
      });
    }
  };

  const login = async ({values}) => {
    try {
      setLoading(true);
      const response = await HttpAuth.post('/v1/auth/login', values);
      const resp = response.data;
      await verifyMFA({
        user: resp,
        message: response.message,
        toastType: response.status,
      });
    } catch (err) {
      toast({
        message: err.message,
        type: 'Error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScreenContainer>
      <CenteredOverlay hidden={!isPopupVisible}>
        <FlexContainer>
          Session Timed Out Due to Inactivity
          <ClickableBox onClick={() => setIsPopupVisible(false)}>
            OK
          </ClickableBox>
        </FlexContainer>
      </CenteredOverlay>
      <FormContainer>
        <HeaderContainer>
          <BrandName>{Config.brandName}</BrandName>
          <LoginMessage>Login in to your account</LoginMessage>
        </HeaderContainer>

        <Form>
          {formContext => {
            const {values, isValid} = formContext;
            return (
              <LayoutContainer>
                <LayoutFieldsRender
                  layoutFields={[
                    {
                      type: 'email',
                      field: 'email',
                      label: 'Email',
                      required: true,
                      inputProps: {
                        autoComplete: 'off',
                      },
                    },
                    {
                      type: 'password',
                      field: 'password',
                      label: 'Password',
                      required: true,
                      inputProps: {
                        autoComplete: 'new-password',
                      },
                      onKeyUp: async e => {
                        if (e.which == 13) {
                          await login({values});
                        }
                      },
                    },
                  ]}
                  {...formContext}
                />
                <FooterContainer>
                  <Button
                    disabled={!isValid}
                    text="Login"
                    onPress={async () => {
                      await login({values});
                    }}
                    loading={loading}
                  />
                  <Button
                    text="Forgot Password?"
                    onPress={() => {
                      navigate('/forgot-password');
                    }}
                    buttonType={buttonType.linkButton}
                  />
                </FooterContainer>
              </LayoutContainer>
            );
          }}
        </Form>
      </FormContainer>
    </ScreenContainer>
  );
};

export default LoginForm;
