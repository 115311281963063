export const taskFields = {
  _id: 1,
  patientId: {
    _id: 1,
    FirstName: 1,
    MiddleName: 1,
    LastName: 1,
    FullName: 1,
    DOB: 1,
    groupId: {
      _id: 1,
      name: 1,
      PNGroupId: 1,
    },
    currentLocationType: {
      value: 1,
    },
    residenceType: {
      value: 1,
    },
    currentLocation: {
      name: 1,
    },
    residence: 1,
    primaryPractice: {
      _id: 1,
    },
    latestEncounter: {
      taskInfo: {
        _id: 1,
      },
    },
  },
  owners: {
    name: 1,
    _id: 1,
  },
  type: 1,
  tcmDueDate: 1,
  encounterId: {
    _id: 1,
    dischargeDate: 1,
    taskInfo: {
      _id: 1,
      status: 1,
      type: 1,
      taskId: 1,
    },
    tcmNotes: 1,
    tcmTaskPerformedBy: 1,
    status: 1,
    latestEventLocation: {
      name: 1,
    },
    latestEventType: 1,
    patientClass: 1,
  },
  taskId: 1,
  nextTcmDueDate: 1,
  onGoingTcmDueDate: 1,
  isDateBounded: 1,
  status: 1,
  facilityId: {
    name: 1,
  },
};
