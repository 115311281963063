import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {View} from '@unthinkable/react-core-components';
import {useToast} from '@unthinkable/react-toast';
import {useFilter} from '@unthinkable/react-filters';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import routeLink from '../../../constant/routeLink.js';

// import {combineText} from '../../../utils/commonMethod';
// import {TextRenderer} from '@unthinkable/react-text-input';
import {patientDetailsWidgetStyle} from '../screens/style/PatientDetail.style';
import {status} from '../../../constant/status';
import {AutoCompleteFilter} from '../../../components/filter/Filters';
import checkAuthorization, {
  checkPrimaryPractice,
} from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {Button} from '../../../components/button';
import buttonType from '../../../constant/buttonType';
import {HttpAuthService} from '../../../services';
import {getPatientById} from '../service/patient.service';
// import images from '../../../assets/images';
// import {useNavigate} from 'react-router-dom';

// const RenderEdit = () => {
//   return <img src={images.edit} width={20} height={20} />;
// };
export const OutPatientMedication = props => {
  const {state} = useLocation();
  const {patientId} = useParams();
  const {patient: statePatient} = state || {};
  const [patient, setPatient] = useState(statePatient || {});
  const {user} = useAuth();
  const toast = useToast();
  const {notify} = useEventHandler();
  const navigate = useNavigate();
  const filterProps = useFilter({
    params: {
      status: status.active,
    },
  });
  const [loading, setLoading] = useState();
  const {onChangeFilter, filterValues} = filterProps;
  const {params} = filterValues;
  const filter = {
    patientId: patientId,
  };
  const fetchPatient = async () => {
    try {
      if (!patient?._id) {
        const response = await getPatientById(patientId);
        if (response.data) {
          setPatient(response?.data);
        } else {
          navigate('/patient');
        }
      }
    } catch (err) {
      console.log(err);
      navigate('/patient');
    }
  };
  if (params.status) {
    filterValues.filter = {
      ...filterValues.filter,
      ...filter,
      status: params.status,
    };
  } else {
    delete filterValues?.filter?.status;
  }

  const fetchClinicalSummaryHandler = async () => {
    setLoading(true);
    const response = await HttpAuthService.get(
      `${routeLink.fetchClinicalSummary}`,
      {
        params: {patient: patient},
      },
    );
    if (response.type === 'Success') {
      notify &&
        notify('dataUpdated', {
          id: 'outpatientDiagnose',
        });
      notify &&
        notify('dataUpdated', {
          id: 'outpatient-medications',
        });
    }
    toast({
      message: response.message,
      type: response.type,
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchPatient();
  }, []);

  return (
    <View style={patientDetailsWidgetStyle}>
      <TableHeader
        title={''}
        actions={[
          <AutoCompleteFilter
            onChangeFilter={onChangeFilter}
            filterValues={filterValues}
            field={'status'}
            options={[status.active, status.inactive]}
            key={'statusFilter'}
            placeholder={'Status'}
            asParam={true}
          />,

          ...(checkAuthorization(
            user,
            entity.fetchClinicalSummary,
            action.view,
          ) &&
          checkPrimaryPractice(user, patient) &&
          patient?.status === status.active &&
          patient?.outPatientClinicalSummary !== 'Success'
            ? [
                <Button
                  key="fetchClinicalSummary"
                  text="Fetch Clinical Summary"
                  onPress={fetchClinicalSummaryHandler}
                  loading={loading}
                  buttonType={buttonType.linkButtonWithBorder}
                />,
              ]
            : []),
        ]}
      />
      <BasicTable
        params={{...params}}
        variant={'outline'}
        eventSourceId="outpatient-medications"
        api={routeLink.getOutPatientMedication}
        fields={{
          medicine: {
            Name: 1,
          },
          Dose: 1,
          Frequency: 1,
          status: 1,
        }}
        filter={filterValues.filter}
        columns={[
          {
            header: 'Medication Name',
            field: 'medicine.Name',
            type: 'text',
          },
          // {
          //   header: 'Dose',
          //   render: ({row, ...rest}) => {
          //     return (
          //       <TextRenderer
          //         {...rest}
          //         value={
          //           row?.Frequency?.Unit &&
          //           combineText(row?.Dose?.Quantity, row?.Dose?.Units)
          //         }
          //       />
          //     );
          //   },
          // },
          // {
          //   header: 'Frequency',
          //   render: ({row, ...rest}) => {
          //     return (
          //       <TextRenderer
          //         {...rest}
          //         value={
          //           row?.Dose?.Units &&
          //           combineText(row?.Frequency?.Period, row?.Frequency?.Unit)
          //         }
          //       />
          //     );
          //   },
          // },
          {
            header: 'Status',
            field: 'status',
            type: 'string',
            width: '90px',
          },
          // {
          //   render: RenderEdit,
          //   onPress: ({row}) => {
          //     navigate('/patient/details/edit-out-patient-medication-details', {
          //       state: {
          //         id: row._id,
          //         titleName:row.medicine.Name,
          //         patient,
          //       },
          //     });
          //   },
          //   width: 50,
          // },
        ]}
        {...props}
      />
    </View>
  );
};
