import React, {useEffect, useState} from 'react';
import {
  Image,
  Row,
  TouchableOpacity,
  TextInput,
  View,
} from '@unthinkable/react-core-components';

export const SearchFilter = ({
  value,
  onChangeValue,
  placeholder = 'Search',
  width,
  styles,
}) => {
  let {
    SearchIcon,
    CrossIcon,
    containerStyle,
    selectedContainerStyle,
    inputContainerStyle,
    inputStyle,
    iconStyle,
  } = styles;

  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      onChangeValue(inputValue); // Call the function with the debounced value
    }, 300); // Debounce delay

    // Cleanup the previous timeout on dependency change
    return () => {
      clearTimeout(handler); // Clear the timeout if inputValue changes again
    };
  }, [inputValue, onChangeValue]); //
  if (isFocused || value) {
    containerStyle = {...containerStyle, ...selectedContainerStyle};
  }

  return (
    <Row style={{...containerStyle, width}}>
      <View style={inputContainerStyle}>
        <TextInput
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
          }}
          placeholder={placeholder}
          onChangeText={text => {
            setInputValue(text);
          }}
          value={inputValue}
          style={inputStyle}
        />
      </View>
      <TouchableOpacity
        style={{cursor: inputValue ? 'pointer' : 'auto'}}
        onPress={() => {
          if (inputValue) {
            setInputValue('');
          }
        }}>
        <Image style={iconStyle} source={inputValue ? CrossIcon : SearchIcon} />
      </TouchableOpacity>
    </Row>
  );
};
