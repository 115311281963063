import React, {Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {CSpinner} from '@coreui/react';
import {children} from '../routes/AppNavigator';
import {useAuth} from '../modules/auth/hooks/useAuth';
import checkAuthorization from '../services/accessService';
import {action, entity} from '../constant/authorization';
import {AppContentStyle} from '../Layout/DefaultLayout.style';

const resolveRoute = (route, idx) => {
  return (
    route.element && (
      <Route
        key={idx}
        path={route.path}
        exact={route.exact}
        name={route.name}
        element={<route.element />}>
        {route.children &&
          route.children.map((route, idx) => {
            return resolveRoute(route, idx);
          })}
      </Route>
    )
  );
};

const AppContent = () => {
  const {user} = useAuth();
  const visibleRoutes = children.filter(route => {
    let {visible = true} = route;
    if (typeof visible === 'function') {
      visible = visible(user);
    }
    return visible;
  });
  return (
    <Suspense fallback={<CSpinner color="primary" />}>
      <AppContentStyle>
        <Routes>
          {visibleRoutes.map((route, idx) => {
            return resolveRoute(route, idx);
          })}
          <Route
            path="/*"
            element={
              <Navigate
                to={
                  checkAuthorization(user, entity.dashboard, action.view)
                    ? 'dashboard'
                    : 'groups'
                }
                replace
              />
            }
          />
        </Routes>
      </AppContentStyle>
    </Suspense>
  );
};

export default React.memo(AppContent);
