import React from 'react';
import {useTheme} from '@unthinkable/react-theme';
import {useFilter} from '@unthinkable/react-filters';
import {TableHeader} from '../../../components/header/TableHeader';
import {SearchFilter} from '../../../components/filter/Filters';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {routeLink} from '../../../constant';
import {BasicTable} from '../../../components/table/Table';
import {Button} from '../../../components/button';
import buttonType from '../../../constant/buttonType';
import {useInvoke} from '../../../hooks';
import {Confirm} from '../../../components/confirm';
import {Icon} from '../../../components/icon';
import images from '../../../assets/images';
import {STATUS} from '../../../modules/user/constants/UserConstants';
import modalMessages from '../../../constant/ModalMessage';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {action, entity} from '../../../constant/authorization';
import checkAuthorization from '../.../../../../services/accessService';
import {isAdmin} from '../../../utils/checkUserType';

const GroupMembersList = props => {
  const {user} = useAuth();
  const filterProps = useFilter();
  const {searchValue, onChangeFilter, filterValues} = filterProps;
  const {channelId, groupId} = useParams();
  const location = useLocation();
  const {state} = useLocation();
  const {addUser} = useTheme('icons');
  const navigate = useNavigate();
  filterValues.filter = {
    ...filterValues.filter,
    groupId: groupId,
    status: STATUS.ACTIVE,
  };

  const RenderDelete = ({row}) => {
    const {user} = useAuth();
    const invoke = useInvoke({
      method: 'post',
      eventSourceId: 'chatmember',
      throwError: true,
    });
    if (user?.userType?.name === row?.user?.userType?.name) return;
    if (
      isAdmin() ||
      user?.employerType?.name === row?.user?.employerType?.name
    ) {
      return (
        <Confirm
          title={modalMessages.removeChatMember.title}
          message={modalMessages.removeChatMember.message}
          confirmText="Confirm"
          onConfirm={async () => {
            await invoke({
              uri: routeLink.removeGroupMember + '/' + row._id,
              data: {
                channelId,
                userToRemove: '',
              },
            });
          }}>
          <Icon src={images.trash} />
        </Confirm>
      );
    }
  };
  return (
    <BasicTable
      variant={'outline'}
      noDataText={'No Members found'}
      renderHeader={() => (
        <TableHeader
          title={`${state?.groupName || ''} Members`}
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              key={'SearchFilter'}
              placeholder={'Search by name'}
            />,
            checkAuthorization(user, entity.chat, action.create) &&
            !state?.patientId ? (
              <Button
                key="addMember"
                text="Add Member"
                onPress={() => {
                  if (location.pathname.split('/')[1] !== 'patient') {
                    navigate(
                      `/groups/chat/${channelId}/${groupId}/GroupMembersList/AddGroupMember`,
                      {
                        state,
                      },
                    );
                  } else {
                    navigate(
                      `/patient/chat/${channelId}/${groupId}/GroupMembersList/AddGroupMember`,
                      {
                        state,
                      },
                    );
                  }
                }}
                icon={addUser}
                iconPosition="right"
                buttonType={buttonType.linkButtonWithBorder}
              />
            ) : null,
          ]}
        />
      )}
      search={searchValue}
      searchFields={['user.name']}
      sort={{name: 1}}
      api={`${routeLink.getChatMembers}`}
      addOnFilter={JSON.stringify(filterValues.filter)}
      eventSourceId="chatmember"
      fields={{
        user: {
          name: 1,
          email: 1,
          practiceId: 1,
          facilityId: 1,
          userType: {
            name: 1,
          },
          employerType: {
            name: 1,
          },
        },
      }}
      columns={[
        {
          header: 'Name',
          field: 'user.name',
          type: 'text',
        },
        {
          render: RenderDelete,
          width: 50,
          visible: () => checkAuthorization(user, entity.chat, action.delete),
        },
      ]}
      {...props}
    />
  );
};

export default GroupMembersList;
