import styled from 'styled-components';

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1;
  background-color: #f3f5f7;
  border-left: 1px solid #e4e4e7;
  padding: 6px;
  overflow-x: hidden;
  margin: -16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CompletionCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;

export const ChartContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 16px;
`;

export const TodayTasksContainer = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 40%;
  }
`;

export const Stats = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 16px;
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 60%;
  }
`;

export const PracticeStatusChartStyle = styled.div`
  width: 100%;
  overflow-x: auto;
  background-color: #fff;
  scrollbar-width: thin;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensures loader is on top of the chart */
`;
