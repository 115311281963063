import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {View} from '@unthinkable/react-core-components';

import images from '../../../assets/images';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import {Icon} from '../../../components/icon';
import {Button} from '../../../components/button/Button.js';
import buttonType from '../../../constant/buttonType.js';
import {useInvoke} from '../../../hooks/useInvoke.js';
import routeLink from '../../../constant/routeLink.js';
import {Confirm} from '../../../components/confirm/index.js';
import Config from '../../../Config';
import {FilePreviewer} from '../../../components/filePreviewer';
import {patientDetailsWidgetStyle} from './style/PatientDetail.style';

const RenderDelete = ({row}) => {
  const deleteDocument = useInvoke({
    method: 'delete',
    eventSourceId: ['patient-documents'],
    throwError: true,
  });
  return (
    <Confirm
      title="Delete"
      secondaryTitle={row.name}
      message="Are you sure you want to delete this Document?"
      confirmText="Delete"
      variant="error"
      onConfirm={async () => {
        await deleteDocument({
          uri: routeLink.patientDocuments + '/' + row._id,
        });
      }}>
      <Icon src={images.trash} />
    </Confirm>
  );
};

export const PatientsDocuments = props => {
  const {patientId} = useParams();
  const navigate = useNavigate();

  return (
    <View style={patientDetailsWidgetStyle}>
      <TableHeader
        title={''}
        actions={[
          <Button
            key="addPractice"
            text="Add File"
            onPress={() => {
              navigate('add-documents');
            }}
            icon={images.Upload}
            iconPosition="right"
            buttonType={buttonType.linkButtonWithBorder}
          />,
        ]}
      />
      <BasicTable
        variant={'outline'}
        api="/v1/patientDocuments"
        filter={{
          patientId: patientId,
        }}
        eventSourceId="patient-documents"
        sort={{
          createdAt: -1,
        }}
        columns={[
          {
            header: 'File Name',
            field: 'name',
            type: 'text',
          },
          {
            header: 'Uploaded On',
            field: 'createdAt',
            type: 'date',
            formatOptions: {
              format: Config.dateTimeFormat,
            },
            width: 200,
          },
          {
            render: FilePreviewer,
            width: 50,
            field: 'file',
          },
          {
            render: RenderDelete,
            width: 50,
          },
        ]}
        {...props}
      />
    </View>
  );
};
