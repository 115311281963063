import React, {useState} from 'react';
import {Image, TouchableOpacity} from '@unthinkable/react-core-components';
import images from '../../../assets/images';
import {
  MessageInputBoxContainer,
  MessageInputTextArea,
  SendMessageDivStyle,
} from '../styles/MessageInputBox.style';

const MessageInputBox = ({onSendMessage, isSending}) => {
  const [message, setMessage] = useState('');
  const handleMessageChange = event => {
    setMessage(event.target.value);
  };
  const sendMessage = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage !== '') {
      onSendMessage(trimmedMessage);
      setMessage('');
    }
  };
  return (
    <MessageInputBoxContainer
      onKeyUp={e => {
        if (e.shiftKey && e.keyCode === 13) {
          e.preventDefault();
        } else if (e.which == 13 && message) {
          sendMessage();
        }
      }}>
      <MessageInputTextArea
        value={message}
        onChange={handleMessageChange}
        placeholder={isSending ? 'Sending...' : 'Type your message...'}
        autoFocus
      />
      <TouchableOpacity
        style={{
          cursor: message ? 'pointer' : 'auto',
          ...SendMessageDivStyle,
        }}
        onPress={() => {
          if (message) {
            sendMessage();
          }
        }}>
        <Image
          style={{
            width: 22,
            height: 22,
            opacity: message ? '1' : '0.4',
          }}
          source={images.sendAlt}
        />
      </TouchableOpacity>
    </MessageInputBoxContainer>
  );
};

export default MessageInputBox;
