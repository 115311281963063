import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useFilter} from '@unthinkable/react-filters';
import {TableHeader} from '../../../components/header/TableHeader';
import Config from '../../../Config';
import {useAuth} from '../../auth/hooks/useAuth';
import {routeLink} from '../../../constant';
import {TakTableStyle, TaskTablesContainer} from '../styles/TaskTable.style';
import {TASK_TYPE, TASK_TYPE_TO_SHOW} from '../../../constant/taskType';
import {CardStyle} from '../../../components/card/taskCard.style';
import {
  CardBody,
  CardFooter,
  CardHeader,
} from '../../../components/card/taskCard';
import Table from '../../../components/table/Table';
import {taskFields} from '../../../constant/task.fields';
import {TaskFilter} from '../TaskFilter';
import {TaskAction} from '../../../services/taskService';
const {defaultListLimit} = Config;

export const TaskTable = props => {
  const {searchValue, filterValues} = useFilter();
  const {user} = useAuth();
  const navigate = useNavigate();

  const {filter, taskTypes} = TaskFilter({user});
  filterValues.filter = {
    ...filterValues.filter,
    ...filter,
  };
  const renderTaskCard = ({row}) => {
    if (!row) {
      return 'NO Data found';
    }
    const action = TaskAction(row);
    const actions = [action];
    return (
      <CardStyle>
        <CardHeader item={row} actions={actions} />
        <CardBody item={row} />
        <CardFooter item={row} />
      </CardStyle>
    );
  };
  const columns = [
    {
      render: renderTaskCard,
    },
  ];
  const fields = taskFields;

  return (
    <TaskTablesContainer>
      {taskTypes.map((taskType, index) => {
        const eventSourceId =
          taskType === TASK_TYPE.FACILITY_ASSIGNMENT
            ? 'facilityAssignment'
            : taskType === TASK_TYPE.INITIAL_TCM_ELIGIBILITY
            ? 'initialTcmEligibility'
            : taskType;
        return (
          <TakTableStyle key={index}>
            <Table
              reduxKey={`${taskType}Table${index}`}
              renderHeader={() => (
                <TableHeader title={TASK_TYPE_TO_SHOW[taskType]} />
              )}
              onRowPress={({row}) => {
                navigate(
                  `/patient/details/${row?.patientId?._id}/tcmEncounter`,
                  {
                    state: {
                      patient: row.patientId,
                      encounter: row.encounterId,
                    },
                  },
                );
              }}
              noDataText={'No task found'}
              style={{
                border: true,
                rowBorder: true,
                columnPadding: true,
                container: {
                  backgroundColor: '#F3F6FA',
                  width: '100%',
                  flex: 1,
                },
              }}
              sort={{isDateBounded: -1, onGoingTcmDueDate: 1, _id: 1}}
              search={searchValue}
              searchFields={[
                'patientId.FullName',
                'patientId.currentLocation.name',
                'patientId.currentLocationType.value',
              ]}
              api={`${routeLink.getTCMTask}`}
              eventSourceId={eventSourceId}
              filter={JSON.stringify({type: taskType})}
              addOnFilter={JSON.stringify(filterValues.filter)}
              perPage={defaultListLimit}
              fields={fields}
              columns={columns}
              marginPagesDisplayed={4}
              {...props}
            />
          </TakTableStyle>
        );
      })}
      <TakTableStyle>
        <Table
          reduxKey={`NonBillableTaskTable`}
          renderHeader={() => <TableHeader title={'Non-Billable'} />}
          onRowPress={({row}) => {
            navigate('/tasks/patient/details/tcmEncounter', {
              state: {
                patient: row.patientId,
                selectedEncounter: row.encounterId,
              },
            });
          }}
          noDataText={'No task found'}
          style={{
            border: true,
            rowBorder: true,
            columnPadding: true,
            container: {
              backgroundColor: '#F3F6FA',
              maxWidth: '400px',
            },
          }}
          sort={{isDateBounded: -1, onGoingTcmDueDate: 1, _id: 1}}
          search={searchValue}
          searchFields={[
            'patientId.FullName',
            'patientId.currentLocation.name',
            'patientId.currentLocationType.value',
          ]}
          api={`${routeLink.getTCMTask}`}
          eventSourceId={`tcmtask`}
          filter={{
            'encounterId.status': "{$in:['Ineligible']}",
            type: `{$in:${taskTypes}}`,
          }}
          addOnFilter={JSON.stringify(filterValues.filter)}
          perPage={defaultListLimit}
          fields={fields}
          columns={columns}
          marginPagesDisplayed={4}
          {...props}
        />
      </TakTableStyle>
    </TaskTablesContainer>
  );
};
