import React, {useEffect, useMemo, useRef} from 'react';
import {Table as TableComponent} from '@unthinkable/react-table';
import {ActivityIndicator} from '@unthinkable/react-activity-indicator';
import {HttpAuthService} from '../../services';
import {tableStyles} from './Table.styles';
import {useStyles, useTheme} from '@unthinkable/react-theme';
import {useToast} from '@unthinkable/react-toast';
import images from '../../assets/images';
import {Image, Text} from '@unthinkable/react-core-components';
import {LabelContainer, Paginate} from '../Paginate.style';
import {LOAD_INIT, SET_PAGE} from './TableReduxConsts.js';
import {connect} from 'react-redux';
import config from '../../Config';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {useAuth} from '../../modules/auth/hooks/useAuth';
import {useMediaQuery} from '../../modules/auth/hooks/useMediaquery';
function loadData(props) {
  return {
    type: LOAD_INIT,
    payload: props,
  };
}

function loadPageCount(props) {
  return {
    type: 'PAGE_COUNT_INIT',
    payload: props,
  };
}

function loadPage({reduxKey, api, page}) {
  return {
    type: SET_PAGE,
    page: page,
    reduxKey: reduxKey ? reduxKey : api,
  };
}

export const BasicTable = props => {
  const {style} = props;
  const toast = useToast();
  const {colors} = useTheme();
  let styles = useStyles(tableStyles);
  styles = {
    ...styles,
    ...style,
  };
  const fetch = async ({uri, props}) => {
    try {
      const result = await HttpAuthService.get(uri, {
        params: props,
      });
      return result;
    } catch (err) {
      toast({message: err.message, type: 'Error'});
    }
  };

  return (
    <TableComponent
      {...props}
      fetch={fetch}
      styles={styles}
      borderColor={colors.OUTLINE}
      renderLoader={() => <ActivityIndicator />}
    />
  );
};

const generateMemoFilter = filter => {
  const parsedFilter = useMemo(() => {
    if (typeof filter === 'object' || typeof filter === 'string') return filter;
    else {
      try {
        return JSON.parse(filter);
      } catch (err) {
        console.log(err);
        return {};
      }
    }
  }, [JSON.stringify(filter)]);

  const filterKeys = useMemo(() => Object.keys(parsedFilter), [parsedFilter]);
  const filterValues = useMemo(
    () => Object.values(parsedFilter),
    [parsedFilter],
  );
  const stableFilter = useMemo(() => filter, [filterKeys, filterValues]);
  return stableFilter;
};

const PaginatedTable = props => {
  const {
    filter = {},
    addOnFilter = {},
    groupBy = {},
    addonGroupBy = {},
    params = {},
    search = {},
    api,
    error,
    page,
    pageCount,
    getData,
    getPageCount,
    setPage,
    eventSourceId,
    style = {},
    marginPagesDisplayed,
    reduxKey,
    paginationComponentSize = 'large',
    ...rest
  } = props;

  const stableFilter = generateMemoFilter(filter);
  const stableAddOnFilter = generateMemoFilter(addOnFilter);
  const stableParams = generateMemoFilter(params);
  const stableGroupBy = generateMemoFilter(groupBy);
  const stableSearch = generateMemoFilter(search);
  const stableAddonGroupBy = generateMemoFilter(addonGroupBy);

  const toast = useToast();
  const hasMounted = useRef(false);
  const {colors} = useTheme();
  let styles = useStyles(tableStyles);
  styles = {
    ...styles,
    ...style,
  };
  const {logout} = useAuth();
  const onError = async () => {
    await logout();
  };
  useDataFetchEvents({eventSourceId}, () => {
    getData({
      ...props,
      onError,
    });
    getPageCount({
      ...props,
      onError,
    });
  });

  useEffect(() => {
    if (hasMounted.current) {
      setPage({reduxKey, api, page: 0});
    } else {
      hasMounted.current = true;
    }
  }, [
    stableFilter,
    stableAddOnFilter,
    stableGroupBy,
    stableAddonGroupBy,
    stableParams,
    stableSearch,
  ]);

  useEffect(() => {
    getData({
      ...props,
      onError,
    });
  }, [
    stableFilter,
    reduxKey,
    stableAddOnFilter,
    stableGroupBy,
    stableAddonGroupBy,
    stableParams,
    stableSearch,
    page,
  ]);

  useEffect(() => {
    getPageCount({
      ...props,
      onError,
    });
  }, [
    stableFilter,
    reduxKey,
    stableAddOnFilter,
    stableGroupBy,
    stableAddonGroupBy,
    stableParams,
    stableSearch,
  ]);

  const handlePageClick = event => {
    setPage({reduxKey, api, page: event.selected});
  };

  useEffect(() => {
    if (error) {
      toast({message: error.message, type: 'Error'});
    }
  }, [error]);

  const {isMobile} = useMediaQuery();
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
      }}>
      <TableComponent
        {...rest}
        styles={styles}
        borderColor={colors.OUTLINE}
        onEndReachedThreshold={2}
        variant="outline"
        renderLoader={() => <ActivityIndicator />}
      />
      {pageCount > 1 && (
        <Paginate
          onPageChange={handlePageClick}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
          nextLabel={
            <LabelContainer>
              <Text>Next</Text>
              <Image source={images.ArrowRight} />
            </LabelContainer>
          }
          previousLabel={
            <LabelContainer>
              <Image source={images.ArrowLeft} />
              <Text>Previous</Text>
            </LabelContainer>
          }
          forcePage={page}
          marginPagesDisplayed={marginPagesDisplayed || isMobile ? 0 : 4}
          size={paginationComponentSize}
        />
      )}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  const {reduxKey, api} = ownProps;
  return {
    data: state?.table?.[reduxKey ? reduxKey : api]?.data || [],
    loading: state?.table?.[reduxKey ? reduxKey : api]?.loading || false,
    error: state?.table?.[reduxKey ? reduxKey : api]?.error,
    pageCount: state?.table?.[reduxKey ? reduxKey : api]?.pageCount || 0,
    page: state?.table?.[reduxKey ? reduxKey : api]?.page || 0,
    skip:
      state?.table?.[reduxKey ? reduxKey : api]?.page *
        (ownProps?.perPage || config.defaultListLimit) || 0,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getData: props => dispatch(loadData(props)),
    getPageCount: props => dispatch(loadPageCount(props)),
    setPage: props => {
      dispatch(loadPage(props));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginatedTable);
