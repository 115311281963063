import styled from 'styled-components';
import theme from 'src/theme/Theme';

const variantWiseTextColor = {
  normal: theme.colors.NEUTRAL_HIGH,
  warning: theme.colors.PATIENT_NAV_HIGH,
  success: theme.colors.SUCCESS_HIGH,
  alarm: theme.colors.RED_TCM_FORE_COLOR,
};

export const tcmEncounterTableStyle = {
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'hidden',
  margin: '-6px',
  flex: 1,
  justifyContent: 'flex-end',
};

export const EncounterTimeLineStyle = {
  container: {
    // flex: 1,
    padding: '30px 24px 30px 24px',
    border: '1px solid #d5d8dd',
    backgroundColor: '#FAFBFB',
    gap: '12px',
  },
  timeLineContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  heading: {
    fontSize: '16px',
    fontFamily: 'Inter-SemiBold',
    lineHeight: '20px',
    color: '#000',
  },
  selectedEncounterContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  text: {
    color: '#52525B',
    cursor: 'pointer',
  },
  textSelected: {
    color: '#2563EB',
    cursor: 'pointer',
  },
};

export const EncounterTimelineOpenCloseIcon = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 2px 6px;
  margin: 12px -6px 0 -15px;
  position: ${props => (props.isOpen ? 'absolute' : 'absolute')};
  right: ${props => (props.isOpen ? '210px' : '0px')};
  top: 0;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  background-color: ${theme.colors.ICON_BACKGROUND_COLOR_LIGHT};
  z-index: 0;
`;

export const TaskTitle = styled.div`
  font-size: 12px;
  color: ${({variant}) => variantWiseTextColor[variant]};
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`;
