export const TCM_ELIGIBLE_TASKS = [
  'ContactTask',
  'MedicationTask',
  'VisitTask',
  'NotesTask',
];

export const OnboardingTasks = [
  'Facility Assignment',
  'Initial Tcm Eligibility',
  'Facility Onboarding',
];
