import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {FacilityTable} from './FacilityTable';
import {routeLink} from '../../../constant';
import {status} from '../../../constant/status';
import {action, entity} from '../../../constant/authorization';
import checkAuthorization from '../../../services/accessService';
import {FACILITY_TYPE} from '../../../constant/facilityType';

export const FacilityStatsTable = props => {
  const navigate = useNavigate();
  const {user} = useAuth();
  return (
    <FacilityTable
      {...props}
      api={routeLink.getFacilityWithMyPatients}
      fromFacilityStats
      onRowPress={({row}) => {
        const residenceState = {
          redoxKey: row?._id,
          facility: row,
          showPatientType: row?.type === FACILITY_TYPE.SkilledNursing,
        };
        if (checkAuthorization(user, entity.patient, action.viewAll)) {
          residenceState['api'] = routeLink.getPatient;
          residenceState['filter'] = {
            $or: [{residence: row?._id}, {currentLocation: row?._id}],
            status: status.active,
          };
          residenceState['sort'] = {LastName: 1, FirstName: 1};
        } else {
          residenceState['api'] = routeLink.careTeamPatients;
          residenceState['filter'] = {
            user: user._id,
            $or: [
              {patientResidence: row?._id},
              {patientCurrentLocation: row?._id},
            ],
            status: status.active,
            patientStatus: status.active,
          };
        }
        navigate(`/facility-stats/overview/patient/residents`, {
          state: residenceState,
        });
      }}
    />
  );
};
