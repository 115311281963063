import React, {useEffect} from 'react';
import {Col} from '@unthinkable/react-core-components';
import {TableHeader} from '../../../components/header/TableHeader';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from '../../auth/hooks/useAuth';
import {isPracticeEmployee} from '../../../utils/checkEmployerType';
import {Patient_Location_Status} from '../../../modules/patient/constants/PatientConstants';
import TabNavigation from '../../../components/tabNavigation/TabNavigation';
import {routeLink} from '../../../constant';
import {status} from '../../../constant/status';
import {FACILITY_TYPE} from '../../../constant/facilityType';
import {action, entity} from '../../../constant/authorization';
import checkAuthorization from '../../../services/accessService';

export const FacilityStatsOverView = () => {
  const {state} = useLocation();
  const navigate = useNavigate();
  const {facility} = state || {};

  useEffect(() => {
    if (!facility) {
      navigate('/dashboard');
    }
  }, [facility]);

  const params = {};
  const {user} = useAuth();

  if (isPracticeEmployee()) {
    params.primaryPractice = user?.practiceId?._id;
  }
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  thirtyDaysAgo.setHours(0, 0, 0, 0); // Set to start of day

  const residenceState = {
    redoxKey: facility?._id,
    facility,
    showPatientType: facility?.type === FACILITY_TYPE.SkilledNursing,
  };
  if (checkAuthorization(user, entity.patient, action.viewAll)) {
    residenceState['api'] = routeLink.getPatient;
    residenceState['filter'] = {
      $or: [{residence: facility?._id}, {currentLocation: facility?._id}],
      status: status.active,
    };
    residenceState['sort'] = {LastName: 1, FirstName: 1};
  } else {
    residenceState['api'] = routeLink.careTeamPatients;
    residenceState['filter'] = {
      user: user._id,
      $or: [
        {patientResidence: facility?._id},
        {patientCurrentLocation: facility?._id},
      ],
      status: status.active,
      patientStatus: status.active,
    };
  }

  const admissionPlanningState = {
    redoxKey: facility?._id,
    facility,
    admissionPlanningTab: true,
    showPatientType: facility?.type === FACILITY_TYPE.SkilledNursing,
  };

  if (checkAuthorization(user, entity.patient, action.viewAll)) {
    admissionPlanningState['api'] = routeLink.getPatient;
    admissionPlanningState['filter'] = {
      residence: facility?._id,
      status: status.active,
      locationStatus: Patient_Location_Status.OUT,
    };
    admissionPlanningState['sort'] = {LastName: 1, FirstName: 1};
  } else {
    admissionPlanningState['api'] = routeLink.careTeamPatientsAdmissionPlanning;
    admissionPlanningState['filter'] = {
      user: user._id,
      patientResidence: facility?._id,
      status: status.active,
      patientStatus: status.active,
    };
  }
  const transitionalCareState = {
    redoxKey: facility?._id,
    facility,
    transitionalCareTab: true,
    showPatientType: facility?.type === FACILITY_TYPE.SkilledNursing,
  };

  if (checkAuthorization(user, entity.patient, action.viewAll)) {
    transitionalCareState['api'] = routeLink.getPatient;
    transitionalCareState['filter'] = {
      locationStatus: Patient_Location_Status.HOME,
      lastEncounterDischargeDate: {$gte: thirtyDaysAgo},
      residence: facility?._id,
      status: status.active,
    };
    transitionalCareState['sort'] = {
      lastEncounterDischargeDate: -1,
      LastName: 1,
      FirstName: 1,
    };
  } else {
    transitionalCareState['api'] = routeLink.careTeamPatientsTransitionalCare;
    transitionalCareState['filter'] = {
      user: user._id,
      patientResidence: facility?._id,
      status: status.active,
      patientStatus: status.active,
    };
  }
  const tabs = [
    {
      name: 'Residents',
      route: 'patient/residents',
      state: residenceState,
    },
    {
      name: 'Admission Planning',
      route: 'patient/admissionPlanning',
      state: admissionPlanningState,
    },
    {
      name: 'Transitional Care',
      route: 'patient/transitionalCare',
      state: transitionalCareState,
    },
    {
      name: 'Care Teams',
      route: 'careProviders',
      state: {
        facility: facility,
        fromFacilityStatsOverview: true,
      },
    },
    {
      name: 'Chat',
      route: 'facility/chat',
      state: {
        facility: facility,
        fromFacilityStatsOverview: true,
      },
    },
  ];

  return (
    <Col
      gap={8}
      style={{
        flex: 1,
      }}>
      <TableHeader title={facility?.name} />
      <TabNavigation
        tabs={tabs}
        styles={{
          TabContainer: {
            borderRadius: '10px',
          },
          TabContent: {
            border: 'none',
            padding: '0px',
            paddingTop: '10px',
          },
        }}
      />
    </Col>
  );
};
