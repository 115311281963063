import React from 'react';
import {Form} from '../../../components/form';
import {TableHeader} from '../../../components/header/TableHeader';
import {routeLink} from '../../../constant';
import {useFormSubmit} from '../../../hooks';
import {nameValidation} from '../../../utils/validations/commonValidations';
import {useLocation} from 'react-router-dom';

export const AddFacility = props => {
  const {state = {}} = useLocation();
  const {isRedoxFacility = true} = state || {};
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.getFacilities}`,
    method: 'post',
  });

  return (
    <>
      <TableHeader
        title={`Add New ${isRedoxFacility ? 'Redox ' : ''}Facility`}
        secondaryTitle={''}
      />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        defaultValues={{
          isRedoxFacility,
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'text',
                label: 'Name',
                field: 'name',
                size: 'medium',
                required: true,
                validate: nameValidation,
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};
