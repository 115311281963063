import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {PatientDetailsContainer} from './style/PatientDetail.style';
import TabNavigation from '../../../components/tabNavigation/TabNavigation';
import {PatientInfo} from './PatientInfo';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {HttpAuthService} from '../../../services';
import {routeLink} from '../../../constant';
import {patientFields} from '../../../constant/patient.fields';

export const PatientDetails = () => {
  const {patientId} = useParams();
  const {state} = useLocation();
  const {patient: statePatient} = state || {};
  const {user} = useAuth();
  const navigate = useNavigate();
  const [patient, setPatient] = useState(statePatient || {});
  const getPatient = async () => {
    try {
      const response = await HttpAuthService.get(routeLink.getPatient, {
        params: {
          filter: {
            _id: patientId,
          },
          fields: patientFields,
        },
      });
      if (response?.data?.length) setPatient(response.data[0]);
      else navigate('/patient');
    } catch (err) {
      console.log(err);
      navigate('/patient');
    }
  };
  useEffect(() => {
    getPatient();
  }, []);
  useDataFetchEvents(
    {
      eventSourceId: [
        'patient-details',
        'facilityAssignment',
        'patientClassification',
        'transitional-event',
        'tcm-encounters',
      ],
    },
    () => {
      getPatient();
    },
  );
  const tabs = [
    {
      name: 'Care Team Members',
      route: `/patient/details/${patientId}/careTeamMembers`,
      state: {
        patient,
      },
    },
    {
      name: 'TCM Encounters',
      route: `/patient/details/${patientId}/tcmEncounter`,
      state: {
        patient,
      },
    },
    {
      name: 'Outpatient Diagnosis',
      route: `/patient/details/${patientId}/outpatientDiagnosis`,
      state: {
        patient,
      },
    },
    {
      name: 'Outpatient Medications',
      route: `/patient/details/${patientId}/outpatientMedications`,
      state: {
        patient,
      },
    },
    {
      name: 'Documents',
      route: `/patient/details/${patientId}/patientDocuments`,
      state: {
        patient,
      },
    },
    ...(user.chatAuthKey && patient?.groupId
      ? [
          {
            name: 'Chat',
            route: `/patient/details/${patientId}/chat/${patient?.groupId?.PNGroupId}/${patient?.groupId?._id}`,
            state: {
              patient,
              group: patient.groupId,
            },
          },
        ]
      : []),
  ];
  return (
    <PatientDetailsContainer>
      <PatientInfo patient={patient} />
      <TabNavigation tabs={tabs} />
    </PatientDetailsContainer>
  );
};
