import {getNumberOfLineStyle} from '@unthinkable/react-core-components/src/style-utility';
import styled from 'styled-components';

export const Icon = styled.img`
  height: 14px;
  width: 14px;
  cursor: pointer;
  ${({styles}) => styles}
`;
export const PercentageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 4px 2px 4px;
  color: #fff;
  font-size: 12px;
  weight: 500;
  line-height: 17px;
  border-radius: 4px;
  gap: 3px;
  background-color: ${props => {
    if (props.type === 'positive') {
      return '#097C30';
    } else {
      return '#D93400';
    }
  }};
`;

export const Heading = styled.div`
  min-height: 43px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #131313;
  ${({styles}) => styles}
`;

// ...getNumberOfLineStyle({numberOfLines: 2}),
export const completionCard = {
  container: {
    alignItems: 'start',
    flex: 1,
    padding: 16,
    border: '1px solid #D6DBE3',
    borderRadius: 16,
    minHeight: 160,
    minWidth: 160,
    backgroundColor: '#fff',
    gap: '8px',
  },
  heading: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 500,
    color: '#131313',
    ...getNumberOfLineStyle({numberOfLines: 2}),
  },
  cardContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
  footer: {alignItems: 'center', gap: '8px'},
  text: {
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: 400,
    color: '#131313',
    ...getNumberOfLineStyle({numberOfLines: 1}),
  },
  count: {
    fontSize: '24px',
    lineHeight: '38px',
    fontWeight: 600,
    color: '#131313',
    ...getNumberOfLineStyle({numberOfLines: 1}),
  },
};
