import React from 'react';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {
  isFacilityUser,
  isMedicalProvider,
  isPracticeUser,
} from '../../../utils/checkUserType';
import {routeLink} from '../../../constant';
import {TCM_ELIGIBLE_TASKS} from '../../../constant/tcmTasks';
import {
  isFacilityEmployee,
  isPracticeEmployee,
} from '../../../utils/checkEmployerType';
import {status} from '../../../constant/status';
import {TodayTasks} from './TodayTasks';
import PracticeStatusChart from './StackedGraph';
import {CompletionCountCard} from './CompletionCountCard';
import {
  CompletionCardContainer,
  DashboardContainer,
  Stats,
} from '../styles/Dashboard.style';

export const Dashboard = () => {
  const {user} = useAuth();
  const encounterFilter = {};
  const filter = {
    status: {$ne: status.complete},
    'patientId.status': status.active,
    type: {$in: TCM_ELIGIBLE_TASKS},
  };

  if (isPracticeEmployee()) {
    encounterFilter.primaryPractice = user?.practiceId;
    filter['patientId.primaryPractice'] = user?.practiceId?._id;
    if (isMedicalProvider() || isPracticeUser()) {
      filter['owners'] = user?._id;
    }
  } else if (isFacilityEmployee()) {
    encounterFilter.residence = user?.facilityId;
    filter['$or'] = [
      {'patient.residence': user?.facilityId},
      {'patient.currentLocation': user?.facilityId},
    ];
    if (isFacilityUser()) {
      filter['owners'] = user?._id;
    }
  }

  const statsData = [
    {
      heading: isFacilityEmployee()?'Current Census':  'Total Patients',
      api: routeLink.getTotalPatientStats,
      field: 'totalPatient',
    },
    {
      heading: 'TCM Encounter Completion',
      api: routeLink.getTcmCompletionStats,
      percentage: true,
      field: 'tcmEncounterCompletionRate',
    },
    {
      heading: 'Patient Re-Admission Rate',
      api: routeLink.getReAdmissionStats,
      percentage: true,
      field: 'reAdmissionRate',
    },
    {
      heading: 'Current Hospitalization',
      api: routeLink.getCurrentHospitalizationStats,
      field: 'currentHospitalization',
    },
  ];

  return (
    <DashboardContainer>
      <Stats>
        <CompletionCardContainer>
          {statsData.map((item, index) => {
            return (
              <CompletionCountCard
                key={index}
                heading={item.heading}
                api={item.api}
                filter={item.filter}
                percentage={item?.percentage}
                field={item.field}
              />
            );
          })}
        </CompletionCardContainer>
        <PracticeStatusChart />
      </Stats>
      <TodayTasks />
    </DashboardContainer>
  );
};
