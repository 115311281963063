import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {View} from '@unthinkable/react-core-components';
import {
  TransitionalEventTable,
  HospitalizationDiagnose,
  HospitalizationMedication,
  PatientHeader,
} from '../components/index';
import {patientDetailStyles} from './style';
import {HttpAuthService} from '../../../services';
import {routeLink} from '../../../constant';
import {ContactAttempts} from '../components/ContactAttemptsTable';
import {status} from '../../../constant/status';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import checkAuthorization, {
  checkPrimaryPractice,
} from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {TableHeader} from '../../../components/header/TableHeader';
import {TASK_TYPE} from '../../../constant/taskType';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';
import {encounterFields} from '../../../constant/encounter.fields';

export const PatientEncounterDetails = ({
  encounter: propEncounter,
  patient: propPatient,
}) => {
  const statePatient = useLocation()?.state?.patient;
  const stateEncounter = useLocation()?.state?.encounter;

  const patient = statePatient || propPatient;
  const encounter = stateEncounter || propEncounter;

  const {user} = useAuth();

  const [latestEncounter, setLatestEncounter] = useState(encounter);
  const taskInfo = latestEncounter?.taskInfo;
  const notesTaskId =
    taskInfo?.find(task => task.type === TASK_TYPE.NotesTask)?._id || null;
  const notesTaskDetail =
    taskInfo?.find(task => task.type === TASK_TYPE.NotesTask) || null;
  const visitTaskDetail =
    taskInfo?.find(task => task.type === TASK_TYPE.VisitTask) || null;
  const medicationTaskDetail =
    taskInfo?.find(task => task.type === TASK_TYPE.MedicationTask) || null;
  const contactTaskDetail =
    taskInfo?.find(task => task.type === TASK_TYPE.ContactTask) || null;

  let isTcmMedicationButtonVisible = false;
  let isTcmVisitTaskButtonVisible = false;
  if (
    checkAuthorization(user, entity.TcmTasks, action.complete) &&
    user?.practiceId?._id === patient?.primaryPractice?._id &&
    medicationTaskDetail?.status &&
    medicationTaskDetail?.status !== status.complete
  ) {
    isTcmMedicationButtonVisible = true;
  }
  if (
    checkAuthorization(user, entity.TcmTasks, action.complete) &&
    user?.practiceId?._id === patient?.primaryPractice?._id &&
    visitTaskDetail?.status &&
    visitTaskDetail?.status !== status.complete
  ) {
    isTcmVisitTaskButtonVisible = true;
  }

  const getEncounter = async () => {
    if (!encounter) return;
    const response = await HttpAuthService.get(routeLink.getTCMEncounter, {
      params: {
        filter: JSON.stringify({
          _id: encounter?._id,
        }),
        fields: encounterFields,
        only: true,
      },
    });
    setLatestEncounter(response?.data);
  };
  useEffect(() => {
    getEncounter();
  }, [encounter]);
  const {isMobile} = useMediaQuery();
  return (
    <View style={patientDetailStyles.styles.container}>
      <PatientHeader
        patient={patient}
        encounter={latestEncounter}
        notesTaskId={notesTaskId}
        visitTaskId={visitTaskDetail?.taskId}
        medicationTaskId={medicationTaskDetail?.taskId}
        isTcmVisitTaskButtonVisible={isTcmVisitTaskButtonVisible}
        isTcmMedicationButtonVisible={isTcmMedicationButtonVisible}
        isGenerateNotesButtonVisible={
          checkAuthorization(user, entity.TcmTasks, action.complete) &&
          checkPrimaryPractice(user, patient) &&
          notesTaskDetail?.status &&
          notesTaskDetail?.status !== status.complete
        }
        isGenerateNotesButtonDisabled={
          isTcmMedicationButtonVisible ||
          isTcmVisitTaskButtonVisible ||
          contactTaskDetail?.status === status.active
        }
        contactTaskId={contactTaskDetail?.taskId}
        isContactTaskButtonVisible={
          checkAuthorization(user, entity.TcmTasks, action.complete) &&
          checkPrimaryPractice(user, patient) &&
          contactTaskDetail?.status &&
          contactTaskDetail?.status !== status.complete
        }
        isCCDVisible={true}
      />
      {latestEncounter?.diagnoseReason && (
        <View
          style={
            isMobile
              ? patientDetailStyles.styles.column
              : patientDetailStyles.styles.row
          }>
          <TableHeader title={'Hospitalization Reason:'} />
          {latestEncounter?.diagnoseReason}
        </View>
      )}
      <View style={patientDetailStyles.styles.column}>
        <TransitionalEventTable patient={patient} encounter={latestEncounter} />
        <HospitalizationDiagnose encounter={latestEncounter} />
        <HospitalizationMedication
          encounter={latestEncounter}
          patient={patient}
          isTcmMedicationButtonVisible={isTcmMedicationButtonVisible}
          entity={'ENCOUNTER'}
          medicationId={null}
        />
        {contactTaskDetail && (
          <ContactAttempts
            patient={patient}
            encounter={latestEncounter}
            taskId={contactTaskDetail.taskId}></ContactAttempts>
        )}
      </View>
    </View>
  );
};
