import styled from 'styled-components';
import theme from '../../../theme/Theme';
export const ColumnTextRender = styled.text`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: ${theme.colors.SIDEBAR_BACKGROUND_HIGH};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width:100%;
`;

export const ColumnTextLink = styled.text`
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 1rem;
  color: ${theme.colors.SIDEBAR_BACKGROUND_HIGH};
  text-decoration:underline;
`;


export const GroupRowText = styled.div`
  flex: 1;
  color: ${theme.colors.NEUTRAL_MEDIUM};
  font-size: 12px;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width:100%;
`;
