import {View} from '@unthinkable/react-core-components';
import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import images from '../../../assets/images';
import {Button} from '../../../components/button';
import {Form} from '../../../components/form';
import {TableHeader} from '../../../components/header/TableHeader';
import {routeLink} from '../../../constant';
import buttonType from '../../../constant/buttonType';
import {useFormSubmit} from '../../../hooks';
import {addMedicationStyle} from '../screens/style/AddMedication.style';
export const CreateNewEncounter = props => {
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.createNewEncounter}`,
    method: 'post',
    eventSourceId: ['tcm-encounters'],
  });
  const {patientId} = useParams();

  const RenderPlusButton = () => {
    const navigate = useNavigate();
    return (
      <View style={addMedicationStyle.buttonContainer}>
        <Button
          key="Add New Facility"
          icon={images.addIcon}
          buttonType={buttonType.linkButtonWithBorder}
          onPress={() => {
            navigate('add-new-facility');
          }}
        />
      </View>
    );
  };
  return (
    <>
      <TableHeader title="Create New Encounter" secondaryTitle={''} />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        defaultValues={{
          patientId: patientId,
          admissionDate: Date.now(),
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'date',
                field: 'admissionDate',
                label: 'Admission Date',
                size: 'medium',
                required: true,
              },
              {
                type: 'autoComplete',
                field: 'patientClass',
                label: 'Classification',
                required: true,
                options: ['Emergency', 'Inpatient', 'Outpatient'],
                size: 'medium',
              },
              {
                type: 'autoComplete',
                field: 'location',
                label: 'Location',
                api: routeLink.getFacilities,
                filter: {
                  isRedoxFacility: true,
                },
                sort: {name: 1},
                suggestionField: 'name',
                size: 'medium',
                required: true,
              },
              {
                render: RenderPlusButton,
                size: 0.5,
              },
              {
                type: 'text',
                label: 'Visit Number',
                field: 'visitNumber',
                size: 'medium',
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};
