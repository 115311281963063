import React, {useContext} from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {io} from 'socket.io-client';
import {cilHome} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {WithModal} from '@unthinkable/react-popper';
import {useStyles} from '@unthinkable/react-theme';
import Config from '../Config';
import {AlertPopupTable} from '../modules/alerts/screens/AlertPopupTable';
import {useAuth} from '../modules/auth/hooks/useAuth';
import HttpAuth from '../services/HttpAuthService';
import {BreadCrumbContainer} from './AppBreadcrumb.style';
import {
  AppHeaderActionContainer,
  Avatar,
  HeaderContainer,
  HeaderIcon,
  UserNameContainer,
  appHeaderStyles,
} from './AppHeader.style';
import AppHeaderAction from './AppHeaderAction';
import {AppBreadcrumb} from './index';
import {useInvoke} from '../hooks';
import {setGlobalClearAuth} from '../Navigation';
import {AuthContext} from '../context/auth/AuthContext';
import images from '../assets/images';
import {Icon} from '../components/icon';
import {ActionPopupTable} from './ActionPopupTable';
import {useToast} from '@unthinkable/react-toast';

const AppHeader = () => {
  const [notifications, setNotifications] = useState([]);
  const {user, logout} = useAuth();
  const toast = useToast();
  const {clearAuth} = useContext(AuthContext);
  const loadNotifications = async () => {
    const {data} = await HttpAuth.get(`/v1/OneLinerAlerts`, {
      params: {
        filter: JSON.stringify({
          watcher: {$elemMatch: {userId: user._id, status: {$ne: 'read'}}},
        }),
        count: true,
      },
    });
    setNotifications(data);
  };
  const handleNewLogin = async () => {
    await logout();
    toast({
      type: 'Error',
      message: 'Logged in from another device',
    });
  };
  useEffect(() => {
    setGlobalClearAuth(clearAuth);
  }, []);
  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    const socket = io(Config.socketUrl);
    socket.on('connect', () => {
      socket.on(`notification-${user._id}`, () => {
        loadNotifications();
      });
      socket.on(`logout-${user._id}`, () => {
        console.log('received');
        handleNewLogin();
      });
    });
    return () => {
      socket.off(`notification-${user._id}`); // Remove specific event listener
      socket.off(`logout-${user._id}`);
      socket.disconnect(); // Disconnect socket
    };
  }, [user._id]);

  const invoke = useInvoke({close: false, method: 'put'});
  const navigate = useNavigate();
  const styles = useStyles(appHeaderStyles);

  return (
    <HeaderContainer>
      <BreadCrumbContainer>
        <CIcon icon={cilHome} size="lg" />
        <AppBreadcrumb />
      </BreadCrumbContainer>

      <AppHeaderActionContainer>
        <WithModal
          position="left"
          hideModal={() => {
            invoke({uri: '/v1/mark-notification-read'});
          }}
          renderModal={AlertPopupTable}
          styles={styles}>
          <AppHeaderAction notifications={notifications.count} />
        </WithModal>
        <HeaderIcon
          alt="settings"
          src={images.settingsDark}
          onClick={() => {
            navigate('/settings');
          }}
        />
        <>
          <Avatar alt="avatar" src={images.user} />
          <UserNameContainer>
            {user.name}
            <WithModal
              position="right"
              hideModal={() => {}}
              renderModal={ActionPopupTable}
              styles={styles}>
              <Icon src={images.showMore} alt="show more" />
            </WithModal>
          </UserNameContainer>
        </>
      </AppHeaderActionContainer>
    </HeaderContainer>
  );
};

export default AppHeader;
