import React, {useContext, useEffect} from 'react';
import {Navigate, Outlet, useRoutes} from 'react-router-dom';
import DefaultLayout from '../Layout/DefaultLayout';
import {useAuth} from '../modules/auth/hooks/useAuth';
import {LoadingScreen} from '../screens/LoadingScreen';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import Modal from '../components/modal';
import Theme from '../theme/Theme';
import {View} from '@unthinkable/react-core-components';
import {setGlobalClearAuth, setGlobalToast} from '../Navigation';
import {AuthContext} from '../context/auth/AuthContext';
import {useToast} from '@unthinkable/react-toast';

const StackScreens = props => {
  const {screens, styles = {}, isProtected} = props || {};
  if (!screens?.length) {
    return [];
  }
  return screens?.map(screen => {
    const type = screen.type || 'modal';
    const outlet = screen.outlet ?? true;
    const Component = screen.component;
    return {
      path: screen.path,
      element: props => {
        return isProtected ? (
          <>
            <ProtectedRoute {...props}>
              <Component {...props} styles={styles.stack} />
            </ProtectedRoute>
            {outlet && <Outlet />}
          </>
        ) : (
          <>
            <PublicRoute>
              <Component {...props} styles={styles.stack} />
            </PublicRoute>
            {outlet && <Outlet />}
          </>
        );
      },
      exact: screen.exact,
      name: screen.name,
      visible: screen.visible,
      children:
        type === 'stack'
          ? StackScreens({screens: screen.children, styles, isProtected})
          : ModalScreens({screens: screen.children, styles, isProtected}),
    };
  });
};

const ModalScreens = props => {
  const {screens, styles = {}, isProtected} = props || {};
  if (!screens?.length) {
    return [];
  }

  const sizeMap = {
    xsmall: 200,
    small: 400,
    medium: 600,
    large: 800,
    xlarge: 1000,
  };

  return screens?.map(screen => {
    const Component = screen.component;
    const {screenOptions = {}, type = 'modal'} = screen;
    const ModalComponent = () => (
      <Modal
        isVisible={true}
        style={{
          alignItems: 'center',
        }}>
        <View
          style={{
            backgroundColor: Theme.colors.BACKGROUND,
            width: sizeMap[screenOptions.modalSize],
            borderRadius: 4,
            padding: 16,
            overflow: 'hidden',
            maxHeight: '100%',
            maxWidth: '80%',
            marginRight: 16,
            marginLeft: 16,
            borderColor: Theme.colors.UPPER_OUTLINE,
            borderWidth: 1,
          }}>
          <Component {...props} styles={styles.modal} />
        </View>
      </Modal>
    );
    return {
      path: screen.path,
      element: props => {
        return isProtected ? (
          <>
            <ProtectedRoute {...props}>
              <ModalComponent />
            </ProtectedRoute>
            <Outlet />
          </>
        ) : (
          <>
            <PublicRoute>
              <ModalComponent />
            </PublicRoute>
            <Outlet />
          </>
        );
      },
      exact: screen.exact,
      name: screen.name,
      screenOptions,
      children:
        type === 'stack'
          ? StackScreens({screens: screen.children, styles, isProtected})
          : ModalScreens({screens: screen.children, styles, isProtected}),
    };
  });
};

// eslint-disable-next-line react/prop-types
export const ProtectedRoute = ({children}) => {
  const {isAuthenticated} = useAuth();
  if (!isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};

// eslint-disable-next-line react/prop-types
export const PublicRoute = ({children}) => {
  const {isAuthenticated} = useAuth();
  if (isAuthenticated) {
    // user is authenticated
    return <Navigate to="/" />;
  }
  return children;
};

export const children = [
  ...StackScreens({screens: PrivateRoutes.stack, isProtected: true}),
  ...ModalScreens({screens: PrivateRoutes.modal, isProtected: true}),
  ...StackScreens({screens: PublicRoutes.stack}),
  ...ModalScreens({screens: PublicRoutes.modal}),
];

export const AppNavigator = () => {
  const {loading, isAuthenticated} = useAuth();
  const {clearAuth} = useContext(AuthContext);
  const toast = useToast();

  useEffect(() => {
    setGlobalClearAuth(clearAuth);
    setGlobalToast(toast);
  }, [clearAuth, toast]);
  if (loading) {
    return <LoadingScreen />;
  }
  const routes = useRoutes([
    {
      path: '/',
      element: <DefaultLayout isAuthenticated={isAuthenticated} />,
      children,
    },
    {
      path: '*',
      element: <Navigate to={isAuthenticated ? '/' : '/login'} />,
    },
  ]);

  return routes;
};
