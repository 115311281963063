import styled from 'styled-components';

export const Icon = styled.img.attrs(({alt}) => ({
  alt: alt || 'icon', // Default alt attribute for accessibility
}))`
  width: ${({width}) => width || '20px'};
  height: ${({height}) => height || '20px'};
  cursor: pointer;
  ${({style}) => style && `${style}`}
`;
//   cursor: ${({clickable}) => (clickable ? 'pointer' : 'default')};
//   filter: ${({color}) => (color === 'gray' ? 'grayscale(100%)' : 'none')};

//   &:hover {
//     transform: ${({clickable}) => (clickable ? 'scale(1.1)' : 'none')};
//     opacity: ${({clickable}) => (clickable ? '0.8' : '1')};
//   }
