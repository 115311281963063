import {routeLink} from '../../../constant';
import {patientFields} from '../../../constant/patient.fields';
import {HttpAuthService} from '../../../services';

export const getPatientById = async patientId => {
  try {
    const response = await HttpAuthService.get(
      `${routeLink.getPatient}/${patientId}`,
      {
        params: {
          fields: patientFields,
        },
      },
    );
    return response;
  } catch (err) {
    console.log('Error caught in getPatientById', err);
    throw err;
  }
};
