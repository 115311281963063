import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  NavLink,
  NavLinkText,
  NavItem,
  ChildNavLink,
  ParentNavLink,
  NavLinkNotification,
  NavIconText,
} from './AppSidebarNav.style';
import {isMobileOrTablet} from '../utils/isMobileOrTablet';
import {Icon} from '../components/icon';

export const AppSidebarNav = ({items, setSidebarShow}) => {
  const location = useLocation();

  const navLink = (
    name,
    showName = true,
    icon,
    selectedIcon,
    to,
    notificationCount,
    isChild,
  ) => {
    const navigate = useNavigate();
    const isSelected = location.pathname.split('/')[1] == to?.split('/')[1];
    const source = isSelected ? selectedIcon : icon;
    const NavIcon = () => {
      return <Icon src={source} alt={name} />;
    };

    if (isChild)
      return (
        <ChildNavLink
          title={name}
          onClick={() => {
            if (isMobileOrTablet()) {
              setSidebarShow(false);
            }
            navigate(to);
          }}>
          <NavIconText>
            {source && <NavIcon />}
            <NavLinkText>{showName && name && name}</NavLinkText>
            {notificationCount > 0 && !isSelected && (
              <NavLinkNotification>
                {notificationCount && notificationCount}
              </NavLinkNotification>
            )}
          </NavIconText>
        </ChildNavLink>
      );

    return (
      <ParentNavLink
        title={name}
        onClick={() => {
          if (isMobileOrTablet()) {
            setSidebarShow(false);
          }
          navigate(to);
        }}>
        <NavIconText>
          {source && <NavIcon />}
          <NavLinkText>{showName && name && name}</NavLinkText>
        </NavIconText>
        {notificationCount > 0 && !isSelected && (
          <NavLinkNotification>
            {notificationCount && notificationCount}
          </NavLinkNotification>
        )}
      </ParentNavLink>
    );
  };

  const navItem = (item, index, isChild) => {
    const {name, icon, showName, selectedIcon, to, notificationCount, ...rest} =
      item;
    const isSelected = location.pathname.split('/')[1] == to.split('/')[1];

    return (
      <NavItem
        {...(to &&
          !rest.items && {
            component: NavLink,
          })}
        key={index}
        to={to}
        {...rest}
        isSelected={isSelected}>
        {navLink(
          name,
          showName,
          icon,
          selectedIcon,
          to,
          notificationCount,
          isChild,
        )}
      </NavItem>
    );
  };
  const navGroup = (item, index) => {
    const {component, name, showName, icon, ...rest} = item;
    const Component = component;
    let isMenuSelected = false;

    item?.items?.forEach(child => {
      if (location.pathname.split('/')[1] == child.to.split('/')[1]) {
        isMenuSelected = true;
      }
    });

    return (
      <Component
        idx={String(index)}
        key={index}
        name={name}
        icon={icon}
        showName={showName}
        toggler={navLink(name, icon, showName)}
        isMenuSelected={isMenuSelected}
        {...rest}>
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index, true),
        )}
      </Component>
    );
  };

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
    </React.Fragment>
  );
};

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};
