import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useFormSubmit} from '../../../hooks';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {Form} from '../../../components/form';
import {TableHeader} from '../../../components/header/TableHeader';
import {Patient_Type} from '../constants/PatientConstants';
import {routeLink} from '../../../constant';
import {isMedicalProvider} from '../../../utils/checkUserType';
import {getPatientsFullName} from '../../../utils/commonMethod';

export const completePatientClassificationTask = props => {
  const {tcmTaskId} = useParams();
  const {user} = useAuth();
  const {state} = useLocation();
  const {encounter, patient} = state || {};
  const {onSubmit} = useFormSubmit({
    uri: routeLink.completePatientClassificationTask,
    method: 'put',
    eventSourceId: ['patientClassification', 'tcmtasks'],
  });
  const defaultValues = {
    VisitDateTime: Date.now(),
  };
  if (isMedicalProvider()) {
    defaultValues['updatedBy'] = {
      _id: user?._id,
      name: user?.name,
      email: user?.email,
      facilityId: user?.facilityId,
      practiceId: user?.practiceId,
    };
  }

  return (
    <>
      <TableHeader
        title={`Complete Patient Classification Task - ${getPatientsFullName(
          patient,
        )}`}
      />
      <Form
        saveButtonText={'Save'}
        onSubmit={onSubmit}
        updateDefaultValues={defaultValues}
        defaultValues={{
          patientId: patient?._id,
          encounterId: encounter?._id,
          tcmTaskId: tcmTaskId,
        }}
        layoutFields={[
          {
            fields: [
              {
                type: 'autoComplete',
                field: 'patientType',
                label: 'Patient Type',
                options: [
                  {label: Patient_Type.longTermCare, value: 'longTermCare'},
                  {label: Patient_Type.skilledNursing, value: 'skilledNursing'},
                ],
                keyField: 'value',
                suggestionField: 'label',
                size: 'medium',
                required: true,
              },
            ],
          },
        ]}
        {...props}
      />
    </>
  );
};
