const modalMessages = {
  removeChatMember: {
    title: 'Remove Member',
    message: 'Are you sure you want to remove this member?',
  },
  deactivate: {
    title: 'Deactivate',
    message: 'Are you sure you want to deactivate this',
  },
  activate: {
    title: 'Activate',
    message: 'Are you sure you want to activate this',
  },
  changeToLongTermCare: {
    title: 'Change Patient Type',
    message:
      'Are you sure you want to change the patient type to Long Term Care?',
  },
  changeToSkilledNursing: {
    title: 'Change Patient Type',
    message:
      'Are you sure you want to change the patient type to Skilled Nursing?',
  },
  newRequest: {
    title: 'Confirmation',
    approvedMessage: 'Are you sure you want to approve ?',
    completeMessage: 'Are you sure you want to mark as complete ?',
  },
  careTeamMember: {
    title: 'Delete',
    message: 'Are you sure you want to delete this Care Team Member?',
    confirmText: 'Delete',
  },
  tcmTask: {
    title: 'Confirmation',
    message: 'Are you sure you want to complete this task ?',
    confirmText: 'Complete',
  },
  medicationTaskApproval: {
    title: 'Confirmation',
    message: 'Are you sure you want to approve the medication?',
    confirmText: 'Approve',
  },
  diagnoseApproval: {
    title: 'Confirmation',
    message: 'Are you sure you want to approve the diagnose?',
    confirmText: 'Approve',
  },
  medicationReconciliationTaskApproval: {
    title: 'Confirmation',
    message: 'Are you sure you want to approve the medication reconciliation?',
    confirmText: 'Approve',
  },
  removeMedication: {
    title: 'Remove Medication',
    message: 'Are you sure you want to remove the medication?',
    confirmText: 'Remove',
  },
  removeDiagnose: {
    title: 'Remove Diagnose',
    message: 'Are you sure you want to remove the Diagnose?',
    confirmText: 'Remove',
  },
  practice: {
    adminNotSet:
      'You can not activate the practice until the practice admin is set',
    agreementNotSet:
      'You can not activate the practice until the practice agreement is set',
  },
  tcmNotes: {
    title: 'Confirmation',
    message: 'Are you sure to finalize the TCM cycle ?',
    confirmText: 'Finalize',
  },
  requestForm1823: {
    title: 'Request Form1823',
    message: 'Are you sure you want to request form1823 for this patient?',
    confirmText: 'Request',
  },
  form1823: {
    active: 'First needs to be signed by provider.',
    rejected: 'Form is rejected and returned to the provider for correction.',
  },
};
export default modalMessages;
