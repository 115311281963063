// Events
export const USERS_LIST_EVENT_SOURCE_ID = 'USERS_LIST';

// API
export const USERS_API_ENDPOINT = '/v1/users';

//user type
export const USER_TYPE = {
  ADMIN: 'Admin',
  FACILITY_ADMIN: 'Facility admin',
  FACILITY_USER: 'Facility user',
  PRACTICE_ADMIN: 'Practice admin',
  PRACTICE_USER: 'Practice user',
  MEDICAL_PROVIDER: 'Medical provider',
  OFFICE_USER: 'Office user',
};

// STATUS
export const STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  INVITED: 'Invited',
  COMPLETE: 'Complete',
};
