import styled from 'styled-components';
import theme from '../theme/Theme';
const GroupItemContainer = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  flex-direction: row;
  display: flex;
`;

const GroupItemText = styled.div`
  padding-left: 8px;
`;

const SidebarNavContainer = styled.div`
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: ${theme.colors.NEUTRAL_HIGH};
  flex: 1;
  margin-right: -18px;
`;

const BrandContainer = styled.div`
  text-align: center;
  justify-content: center;
  background-color: ${theme.colors.NEUTRAL_HIGH};
`;
const BrandText = styled.text`
  text-align: center;
`;

const GroupItemIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const BrandIcon = styled.img`
  width: 100%;
  height: 100px;
  padding: 4px;
  background-color: ${({isOpen}) =>
    isOpen
      ? theme.colors.ICON_BACKGROUND_COLOR_LIGHT
      : theme.colors.ICON_BACKGROUND_COLOR_DARK};
`;
const SideBarOpenCloseIcon = styled.img`
  width: 27px;
  height: 27px;
  cursor: pointer;
  padding: 2px 6px;
  margin: 12px -15px 0 -6px;
  position: absolute;
  left: ${props => (props.isOpen ? '245px' : '55px')};
  top: 47px;
  font-size: 12px;
  border-radius: 50%;
  background-color: #ebebeb;
  z-index: 1;
  @media (max-width: 767px) {
    left: 0;
  }
`;

export {
  BrandContainer,
  BrandIcon,
  BrandText,
  GroupItemContainer,
  GroupItemIcon,
  GroupItemText,
  SidebarNavContainer,
  SideBarOpenCloseIcon,
};
