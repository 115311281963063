import {EditPatient} from '../screens/editPatient';
import {AddPatientDocuments} from '../screens/AddPatientDocuments';
import {PatientDetails} from '../screens/PatientDetails';
import {PatientTableCareTeam} from '../screens/PatientTableCareTeam';
import {PatientTable} from '../screens/PatientTable';
import {AddMedication} from '../screens/AddMedication';
import {AddMedicineModal} from '../components/addMedicine.modal';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import PatientChatList from '../screens/PatientChatList';
import {EditOutPatientMedicationDetails} from '../components/EditOutPatientMedicationDetails';
import {OutPatientDiagnose, OutPatientMedication} from '../components';
import {PatientsDocuments} from '../screens/PatientDocument';
import ChatMainScreen from '../../chat/screens/ChatMainScreen';
import {EncounterRoutes} from './TcmEncounterRoutes';
import {CareTeamRoutes} from './CareTeamRoutes';
import {TaskRoutes} from './TaskRoutes';

const stackRoutes = [
  {
    name: 'Patients',
    path: '/patient',
    component: PatientTable,
    visible: user => {
      return checkAuthorization(user, entity.patient, action.viewAll);
    },
  },
  {
    name: 'Patients',
    path: '/patient',
    component: PatientTableCareTeam,
    visible: user => {
      return checkAuthorization(user, entity.patient, action.viewOwn);
    },
  },
  {
    name: 'Patients Group',
    path: '/patientgroups',
    component: PatientChatList,
    visible: user => {
      return (
        user?.chatAuthKey &&
        checkAuthorization(user, entity.patientChat, action.view)
      );
    },
  },
  {
    name: 'Patient Details',
    path: '/patient/details/:patientId',
    component: PatientDetails,
    type: 'stack',
    outlet: false,
    children: [
      ...EncounterRoutes,
      ...CareTeamRoutes,
      {
        name: 'OutPatient Diagnosis',
        path: 'outpatientDiagnosis',
        component: OutPatientDiagnose,
        children: [...TaskRoutes],
      },
      {
        name: 'OutPatient Medication',
        path: 'outpatientMedications',
        component: OutPatientMedication,
        children: [...TaskRoutes],
      },
      {
        name: 'Patient Documents',
        path: 'patientDocuments',
        component: PatientsDocuments,
        children: [
          {
            name: 'Add Patient Documents',
            path: 'add-documents',
            component: AddPatientDocuments,
            screenOptions: {
              modalSize: 'medium',
            },
          },
          ...TaskRoutes,
        ],
      },
      {
        name: 'Chat',
        path: 'chat/:channelId/:groupId',
        component: ChatMainScreen,
        visible: user => user?.chatAuthKey,
        children: [...TaskRoutes],
      },
      {
        name: 'Edit Out Patient Medication Details',
        path: 'edit-out-patient-medication-details',
        component: EditOutPatientMedicationDetails,
        screenOptions: {
          modalSize: 'medium',
        },
      },
    ],
    visible: user => {
      return (
        checkAuthorization(user, entity.patient, action.viewAll) ||
        checkAuthorization(user, entity.patient, action.viewOwn)
      );
    },
  },
  {
    name: 'Edit Patient',
    path: '/patient/details/:patientId/edit',
    component: EditPatient,
    visible: user => {
      return checkAuthorization(user, entity.patient, action.editView);
    },
  },
  {
    name: 'Add Medication',
    path: '/patient/details/:patientId/tcmEncounter/add-medication',
    component: AddMedication,
    children: [
      {
        name: 'Add Medicine',
        path: '/patient/details/:patientId/tcmEncounter/add-medication/medicine',
        component: AddMedicineModal,
      },
    ],
  },
];

const modalRoutes = [];

export default {
  stack: stackRoutes,
  modal: modalRoutes,
};
