import React from 'react';
import Chat from './Chat';
import {ChatMainScreenContainer} from '../styles/ChatMainScreen.style';

const ChatMainScreen = props => {
  return (
    <ChatMainScreenContainer>
      <Chat props={props} />
    </ChatMainScreenContainer>
  );
};

export default ChatMainScreen;
