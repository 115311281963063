import theme from '../../../theme/Theme';
import styled from 'styled-components';

const ChatMessageAreaContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  border: ${props =>
    props.ChatType == 'patient' ? `1px solid ${theme.colors.OUTLINE}` : 'none'};
  border-radius: ${props => (props.ChatType == 'patient' ? '8px' : 'none')};
  max-height: ${props => (props.ChatType == 'patient' ? '335px' : 'auto')};
  min-height: 335px;
`;
const BreakLineWithText = styled.div`
  border: 0;
  font-size: 14px;
  height: 1.5em;
  line-height: 1em;
  position: relative;
  text-align: center;
  &::before {
    content: '';
    background: linear-gradient(to right, transparent, gray, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &::after {
    background-color: ${theme.colors.ICON_BACKGROUND_COLOR};
    border-radius: 10px;
    color: ${theme.colors.NEUTRAL_HIGH};
    content: attr(data-content);
    line-height: 1.5em;
    padding: 5px 7px;
    position: relative;
  }
`;

export {BreakLineWithText, ChatMessageAreaContainer};
