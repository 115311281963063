import {LOAD_SUCCESS, LOAD_ERROR, GET_PAGE_COUNT} from './TableReduxConsts.js';
export function loadSuccess(key, data) {
  return {
    type: LOAD_SUCCESS,
    reduxKey: key,
    data,
  };
}

export function loadError(key, error) {
  return {
    type: LOAD_ERROR,
    reduxKey: key,
    error,
  };
}

export function pageCountSuccess(key, pageCount) {
  return {
    type: GET_PAGE_COUNT,
    reduxKey: key,
    pageCount,
  };
}
