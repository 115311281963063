import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Table} from '../../../components/table';
import images from '../../../assets/images';
import Config from '../../../Config';
import {Box} from '../styles/NewRequestTable.style';
import {TableHeader} from '../../../components/header/TableHeader';
import {SearchFilter} from '../../../components/filter/Filters';
import {useFilter} from '@unthinkable/react-filters';
import {routeLink} from '../../../constant';
import {useInvoke} from '../../../hooks/useInvoke';
import {Button} from '../../../components/button';
import {Confirm} from '../../../components/confirm';
import modalMessages from '../../../constant/ModalMessage';
import {useAuth} from '../../auth/hooks/useAuth';
import {action, entity} from '../../../constant/authorization';
import checkAuthorization from '../../../services/accessService';
import {useTheme} from '@unthinkable/react-theme';
import buttonType from '../../../constant/buttonType';
import {TASK_STATUS} from '../../../constant/taskType';
import {Icon} from '../../../components/icon';

const {defaultListLimit} = Config;

const ActionButton = ({row}) => {
  const navigate = useNavigate();
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: ['newRequest'],
    throwError: true,
  });

  if (row.status === 'New') {
    return (
      <Box>
        <Confirm
          title={`${modalMessages.newRequest.title}`}
          secondaryTitle={row.newFacilityName}
          message={`${modalMessages.newRequest.approvedMessage}`}
          confirmText="Confirm"
          onConfirm={async () => {
            await invoke({
              uri: `${routeLink.newRequest}/${row._id}`,
              data: {status: TASK_STATUS.Approved},
            });
          }}>
          <Icon src={images.checkCircle} title={TASK_STATUS.Approved} />
        </Confirm>

        <Icon
          src={images.closeCircle}
          onClick={() => {
            navigate('/new-requests/reject-reason/' + row._id);
          }}
          title={TASK_STATUS.Reject}
        />
      </Box>
    );
  } else if (row.status === TASK_STATUS.Approved) {
    return (
      <Confirm
        title={`${modalMessages.newRequest.title}`}
        secondaryTitle={row.name}
        message={`${modalMessages.newRequest.completeMessage}`}
        confirmText="Confirm"
        onConfirm={async () => {
          await invoke({
            uri: `${routeLink.newRequest}/${row._id}`,
            data: {status: 'Completed'},
          });
        }}>
        <Button key={TASK_STATUS.Complete} text={TASK_STATUS.Complete} />
      </Confirm>
    );
  } else {
    return null;
  }
};

const NewRequestsTable = props => {
  const filterProps = useFilter();
  const {searchValue, onChangeFilter, filterValues} = filterProps;
  const {user} = useAuth();
  const navigate = useNavigate();
  const {AddFacility} = useTheme('icons');
  const Id = user?._id;
  if (
    checkAuthorization(user, entity.practices, action.request) ||
    checkAuthorization(user, entity.adminFacilities, action.request)
  ) {
    filterValues.filter = {
      ...filterValues.filter,
      requester: Id,
    };
  }
  return (
    <>
      <Table
        noDataText={'No request found'}
        renderHeader={() => (
          <TableHeader
            actions={[
              <SearchFilter
                value={searchValue}
                onChangeFilter={onChangeFilter}
                key={'SearchFilter'}
                placeholder={'Search by name'}
              />,
              checkAuthorization(user, entity.practices, action.request) ||
              checkAuthorization(
                user,
                entity.adminFacilities,
                action.request,
              ) ? (
                <Button
                  key="newRequest"
                  text="Create New Request"
                  onPress={() => {
                    navigate(`/new-requests/${Id}/newRequest`);
                  }}
                  icon={AddFacility}
                  iconPosition="right"
                  buttonType={buttonType.linkButtonWithBorder}
                />
              ) : null,
            ]}
          />
        )}
        search={searchValue}
        searchFields={['name']}
        sort={{createdAt: -1}}
        api={routeLink.newRequest}
        eventSourceId={['newRequest']}
        addOnFilter={JSON.stringify(filterValues.filter)}
        perPage={defaultListLimit}
        fields={{
          type: {
            name: 1,
          },
          name: 1,
          contactName: 1,
          email: 1,
          phoneNumber: 1,
          practiceId: {
            name: 1,
          },
          status: 1,
          rejectedReason: 1,
          requester: {
            name: 1,
            practiceId: {
              name: 1,
            },
            facilityId: {
              name: 1,
            },
          },
        }}
        columns={[
          {
            header: 'Employer',
            value: ({row}) =>
              row.requester?.facilityId
                ? row.requester?.facilityId.name
                : row.requester?.practiceId.name,
          },
          {
            header: 'Requested By',
            field: 'requester.name',
            type: 'text',
          },
          {
            header: 'Type',
            field: 'type.name',
            type: 'text',
          },
          {
            header: 'Name',
            field: 'name',
            type: 'text',
          },
          {
            header: 'Contact Name',
            field: 'contactName',
            type: 'text',
          },
          {
            header: 'Contact Email',
            field: 'email',
            type: 'text',
          },
          {
            header: 'Contact Number',
            field: 'phoneNumber',
            type: 'text',
          },
          {
            render: ActionButton,
            width: 120,
            visible:
              !checkAuthorization(user, entity.practices, action.request) &&
              !checkAuthorization(user, entity.adminFacilities, action.request),
          },
        ]}
        {...props}
      />
    </>
  );
};

export {NewRequestsTable};
