import styled from 'styled-components';
export const HeaderContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 16px;
  border-bottom: 1px solid #d5d8dd;
  @media (max-width: 425px) {
    flex-direction: column-reverse;
    gap: 12px;
    padding: 10px;
  }
`;

export const BreadCrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AppHeaderActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  @media (max-width: 425px) {
    width: 100%;
    justify-content: flex-end;
  }
`;

export const UserNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
export const HeaderIcon = styled.img`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

export const Avatar = styled.img`
  height: 40px;
  width: 40px;
  cursor: pointer;
  ${({styles}) => styles}
`;
export const appHeaderStyles = () => {
  return {
    container: {
      marginTop: 32,
      alignSelf: 'flex-end',
      float: 'inline-end',
      padding: 8,
      maxHeight: 525,
      maxWidth: 400,
    },
  };
};
