import React, {useEffect, useState} from 'react';
import {Text, View} from '@unthinkable/react-core-components';
import queryString from 'query-string';
import {useLocation, useNavigate} from 'react-router-dom';
import {routeLink, toasterMessage, tokenType} from '../../../constant';
import HttpAuth from '../../../services/HttpAuthService';
import {useToast} from '@unthinkable/react-toast';
import {UserService} from '../../../services';
import {
  BrandName,
  FooterContainer,
  FormContainer,
  HeaderContainer,
  LayoutContainer,
  LoginMessage,
  ScreenContainer,
} from './LoginForm.style';
import {LayoutFieldsRender} from '@unthinkable/react-form';
import {Button} from '../../../components/button';
import {Form} from '../../../components/form';
import buttonType from '../../../constant/buttonType';
import {Checkbox} from '@unthinkable/react-checkbox';
import Config from '../../../Config';

// const link = (
//   <a href={Config.ToSLink} target="_blank" rel="noreferrer">
//     here
//   </a>
// );
const Terms = (
  <div>
    {`By check this box you agree to the Terms of Service of ${Config.brandName}
    Technologies, Inc which can be found `}
    <a href={Config.ToSLink} target="_blank" rel="noreferrer">
      here
    </a>
  </div>
);

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {token, type} = queryString.parse(location.search);
  const [isValidLink, setIsValidLink] = useState(true);
  const [tokenId, setTokenId] = useState(null);
  let label = {
    title: 'Reset Password',
    buttonText: 'Reset Password',
  };

  if (type == tokenType.INVITE) {
    label = {
      title: 'Set Password',
      buttonText: 'Submit',
    };
  }

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const data = await UserService.verifyResetPasswordToken(token, type);
        if (data?.success) {
          setIsValidLink(true);
          setTokenId(data.data);
        } else {
          setIsValidLink(false);
          setTokenId(null);
        }
      } catch (error) {
        setIsValidLink(false);
        setTokenId(null);
      }
    };
    verifyToken();
  }, []);
  const getToastMessage = (type, res) => {
    if (type === tokenType.INVITE) {
      if (res.user.isSuperAdmin) {
        return toasterMessage.setPassword.admin.success;
      } else {
        return toasterMessage.setPassword.notAdmin.success;
      }
    } else {
      return toasterMessage.resetPassword.success;
    }
  };

  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const resetPassword = async ({values}) => {
    try {
      setLoading(true);
      const res = await HttpAuth.post(routeLink.resetPasswordLink, {
        ...values,
        tokenId,
      });
      toast({
        message: getToastMessage(type, res),
        type: 'Success',
      });
      !res.user.isSuperAdmin
        ? navigate('/two-factor-authentication', {
            state: {
              user: res?.user,
              type: type,
              token,
            },
          })
        : navigate('/login');
    } catch (err) {
      toast({
        message: err.message,
        type: 'Error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScreenContainer>
      <FormContainer>
        <HeaderContainer>
          <BrandName>{Config.brandName}</BrandName>
          <LoginMessage>{label.title}</LoginMessage>
        </HeaderContainer>
        <Form>
          {formContext => {
            const {values, isValid} = formContext;
            return (
              <LayoutContainer>
                <LayoutFieldsRender
                  layoutFields={[
                    {
                      render: () => {
                        return (
                          <View>
                            <Text style={{color: 'red', textAlign: 'center'}}>
                              {toasterMessage?.resetPassword?.invalidLink}
                            </Text>
                          </View>
                        );
                      },

                      visible: !isValidLink,
                    },
                    {
                      type: 'password',
                      field: 'newPassword',
                      label: 'New Password',
                      required: true,
                      visible: isValidLink,
                    },
                    {
                      type: 'password',
                      field: 'confirmNewPassword',
                      label: 'Confirm New Password',
                      required: true,
                      visible: isValidLink,
                      onKeyUp: async e => {
                        if (e.which == 13) {
                          if (
                            type == tokenType.INVITE &&
                            values.isTermsAccepted
                          ) {
                            await resetPassword({values});
                          } else if (type != tokenType.INVITE) {
                            await resetPassword({values});
                          }
                        }
                      },
                    },
                    {
                      field: 'isTermsAccepted',
                      render: Checkbox,
                      required: true,
                      label: Terms,
                      visible: isValidLink && type == tokenType.INVITE,
                    },
                  ]}
                  {...formContext}
                />
                <FooterContainer>
                  <Button
                    disabled={
                      !isValid ||
                      !values.newPassword ||
                      !values.confirmNewPassword ||
                      values.newPassword !== values.confirmNewPassword ||
                      !isValidLink ||
                      (!values.isTermsAccepted && type == tokenType.INVITE)
                    }
                    text={label.buttonText}
                    loading={loading}
                    onPress={async () => {
                      await resetPassword({values});
                    }}
                  />
                  <Button
                    text="Login"
                    onPress={() => {
                      navigate(routeLink.loginLink);
                    }}
                    buttonType={buttonType.linkButton}
                  />
                </FooterContainer>
              </LayoutContainer>
            );
          }}
        </Form>
      </FormContainer>
    </ScreenContainer>
  );
};

export default ResetPasswordForm;
