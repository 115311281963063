import React from 'react';
import {View} from '@unthinkable/react-core-components';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {routeLink} from '../../../constant';
import {useLocation} from 'react-router-dom';
import images from '../../../assets/images';
import {Button} from '../../../components/button/Button.js';
import buttonType from '../../../constant/buttonType';
import {useNavigate} from 'react-router-dom';
import {addMedicationStyle} from './style/AddMedication.style';

const RenderPlusButton = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  return (
    <View style={addMedicationStyle.buttonContainer}>
      <Button
        key="addMedication"
        icon={images.addIcon}
        buttonType={buttonType.linkButtonWithBorder}
        onPress={() => {
          navigate(state.entity === 'TCMTASK' ? `medicine` : `medicine`, {
            state: {
              encounter: state?.encounter,
              patient: state?.patient,
            },
          });
        }}
      />
    </View>
  );
};

export const AddMedication = props => {
  const {state} = useLocation();
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.getOutPatientMedication}`,
    eventSourceId: 'addMedication',
    method: 'post',
  });
  return (
    <Form
      saveButtonText={'Save'}
      onSubmit={onSubmit}
      defaultValues={{
        encounterId: state?.encounter?._id,
      }}
      layoutFields={[
        {
          label: 'Medicine',
          fields: [
            {
              type: 'autoComplete',
              field: 'medicine',
              label: 'Medicine',
              api: routeLink.getMedicine,
              sort: {Name: 1},
              suggestionField: 'Name',
              size: 11.5,
              required: true,
            },
            {
              render: RenderPlusButton,
              size: 0.5,
            },
          ],
        },
        {
          label: 'Dose',
          fields: [
            {
              type: 'text',
              field: 'Dose.Quantity',
              label: 'Quantity',
              size: 'medium',
              required: true,
            },
            {
              type: 'text',
              field: 'Dose.Units',
              label: 'Units',
              size: 'medium',
              required: true,
            },
          ],
        },
        {
          label: 'Frequency',
          fields: [
            {
              type: 'text',
              field: 'Frequency.Period',
              label: 'Period',
              size: 'medium',
              required: true,
            },
            {
              type: 'text',
              field: 'Frequency.Unit',
              label: 'Unit',
              size: 'medium',
              required: true,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
