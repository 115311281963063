import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {View, Text, Row} from '@unthinkable/react-core-components';
import {useToast} from '@unthinkable/react-toast';
import {
  ActiveTaskRow,
  EncounterTaskStyle,
} from '../screens/style/patientInfo.style';
import {getRemainingDaysMessage, getVariant} from '../../../utils/commonMethod';
import {TASK_STATUS, TASK_TYPE, Tcm_TASKS} from '../../../constant/taskType';
import images from '../../../assets/images';
import checkAuthorization from '../../../services/accessService';
import {action, entity} from '../../../constant/authorization';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {Icon} from '../../../components/icon';
import modalMessages from '../../../constant/ModalMessage';
import {isFacilityAdmin} from '../../../utils/checkUserType';

const variantWiseHourGlass = {
  alarm: 'RedhourGlass',
  warning: 'YellowhourGlass',
};

const ActiveTasks = ({activeTasks, encounter, patient}) => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const {pathname} = useLocation();
  const IsFacilityAdmin = isFacilityAdmin();

  const contactTask = activeTasks?.find(
    task => task.type === TASK_TYPE.ContactTask,
  );
  const visitTask = activeTasks?.find(
    task => task.type === TASK_TYPE.VisitTask,
  );
  const medicationTask = activeTasks?.find(
    task => task.type === TASK_TYPE.MedicationTask,
  );

  const handleOnPress = (task, navigatePath, state) => {
    if (
      (Tcm_TASKS[task.type] &&
        checkAuthorization(user, entity.TcmTasks, action.complete)) ||
      checkAuthorization(user, entity[task.type], action.complete)
    ) {
      if (task.type === TASK_TYPE.NotesTask) {
        if (contactTask || visitTask || medicationTask) {
          toast({
            message: 'Please complete  contact, visit, medication tasks first',
            type: 'Error',
          });
          return;
        }
      }
      if (
        IsFacilityAdmin &&
        task.status !== TASK_STATUS.Approved &&
        task.type === TASK_TYPE.Form1823
      ) {
        toast({
          message:
            task.status === TASK_STATUS.Active
              ? modalMessages.form1823.active
              : modalMessages.form1823.rejected,
          type: 'Error',
        });
        return;
      }
      navigatePath.length &&
        navigate(navigatePath, {
          state: {
            encounter,
            patient,
            ...state,
          },
        });
    } else {
      toast({
        message: 'You do not have permission to complete this task',
        type: 'Error',
      });
    }
  };

  return (
    <View style={EncounterTaskStyle.container}>
      <Text style={EncounterTaskStyle.heading}>Active Tasks</Text>
      {activeTasks?.map((task, index) => {
        let taskIcon = {};
        let navigatePath = '';
        let state = {};
        const variant = getVariant(task, encounter);
        switch (task.type) {
          case TASK_TYPE.ContactTask:
            taskIcon = images[`contact${variant}`];
            navigatePath = `${pathname}/${encounter?._id}/${task?.taskId}/add-contact-attempts`;
            break;
          case TASK_TYPE.VisitTask:
            taskIcon = images[`visit${variant}`];
            navigatePath = `${pathname}/complete-visit/${task?.taskId}`;
            break;
          case TASK_TYPE.MedicationTask:
            taskIcon = images[`medRec${variant}`];
            navigatePath = `${pathname}/complete-medication/${task?.taskId}`;
            break;
          case TASK_TYPE.NotesTask:
            taskIcon = images[`notes${variant}`];
            navigatePath = `${pathname}/tcm-notes`;
            state['taskId'] = task?._id;
            break;
          case TASK_TYPE.Form1823:
            taskIcon = images[`form1823${variant}`];
            navigatePath = `${pathname}/form1823/${task?.taskId}`;
            break;
          case TASK_TYPE.INITIAL_TCM_ELIGIBILITY:
            taskIcon = images[`Tasks${variant}`];
            navigatePath = `${pathname}/initial-eligibility`;
            state['tcmTaskId'] = task._id;
            break;
          case TASK_TYPE.FACILITY_ASSIGNMENT:
            taskIcon = images[`Tasks${variant}`];
            navigatePath = `${pathname}/facility-assignment`;
            state['tcmTaskId'] = task._id;
            break;
          case TASK_TYPE.PATIENT_CLASSIFICATION:
            taskIcon = images[`Tasks${variant}`];
            navigatePath = `${pathname}/patient-classification/${task?._id}`;
            state['tcmTaskId'] = task._id;
            break;
          case TASK_TYPE.DISCHARGE_STATUS:
            taskIcon = images[`Tasks${variant}`];
            navigatePath = `${pathname}/complete-discharge-status/${task?._id}`;
            state['tcmTaskId'] = task._id;
            break;
          default:
        }
        return (
          <ActiveTaskRow
            key={index}
            variant={variant}
            onClick={() => {
              handleOnPress(task, navigatePath, state);
            }}>
            <Row style={EncounterTaskStyle.taskRow}>
              <Icon src={taskIcon} />
              {task.type}
            </Row>
            {task.tcmDueDate && (
              <Row>
                <Icon
                  style={EncounterTaskStyle.Icon}
                  src={images[variantWiseHourGlass[variant]]}
                />
                <Text style={EncounterTaskStyle.dueDateText}>
                  {getRemainingDaysMessage(task)}
                </Text>
              </Row>
            )}
          </ActiveTaskRow>
        );
      })}
    </View>
  );
};

export default ActiveTasks;
