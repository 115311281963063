export const encounterFields = {
  admissionDate: 1,
  dischargeDate: 1,
  currentLocation: {
    name: 1,
  },
  status: 1,
  taskInfo: {
    _id: 1,
    taskId: 1,
    successStatus: 1,
    status: 1,
    type: 1,
    tcmDueDate: 1,
    nextTcmDueDate: 1,
  },
  tcmNotes: 1,
  tcmTaskPerformedBy:1,
  diagnoseReason: 1,
  patientClass: 1,
  latestEventLocation: {
    name: 1,
  },
  ContactTaskDuration: 1,
  MedicationTaskDuration: 1,
  VisitTaskDuration: 1,
};
