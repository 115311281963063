import React from 'react';
import moment from 'moment';
import {Header} from '@unthinkable/react-screen-components';

import {
  ContentContainer,
  Label,
  CardHeaderStyle,
  LeftRectangle,
  HeaderContainer,
  CardStyle,
  PatientTaskCardBody,
} from './taskCard.style';
import {Icon} from '../../components/icon';
import Config from '../../Config';
import images from '../../assets/images';
import {getPatientsFullName} from '../../utils/commonMethod';

export const CardHeader = ({row, actions}) => {
  return (
    <HeaderContainer>
      <LeftRectangle />
      <CardHeaderStyle>
        <Header
          title={getPatientsFullName({
            FirstName: row.FirstName,
            MiddleName: row.MiddleName,
            LastName: row.LastName,
            Render: true,
          })}
          secondaryTitle={moment(row.DOB).utc().format(Config.DOBFormat)}
        />
        {actions &&
          actions?.length &&
          actions.map(action => {
            return action;
          })}
      </CardHeaderStyle>
    </HeaderContainer>
  );
};
const TextWithIcon = props => {
  const {text, icon, iconPosition = 'left'} = props;
  return (
    <ContentContainer gap={14}>
      {iconPosition === 'left' ? <Icon src={icon} alt="icon" /> : void 0}
      <Label>{text}</Label>
      {iconPosition === 'right' ? <Icon src={icon} alt="icon" /> : void 0}
    </ContentContainer>
  );
};

export const CardBody = ({row}) => {
  return (
    <PatientTaskCardBody>
      {row?.currentLocation?.name && (
        <TextWithIcon
          text={row?.currentLocation?.name}
          icon={images.location}
        />
      )}
      {row?.residence?.name && (
        <TextWithIcon text={row?.residence?.name} icon={images.residence} />
      )}
    </PatientTaskCardBody>
  );
};

export const renderPatientCard = props => {
  const {row, HeaderComponent, BodyComponent, FooterComponent, headerAction} =
    props;
  return (
    <CardStyle>
      {HeaderComponent ? (
        <HeaderComponent row={row} actions={headerAction} />
      ) : (
        <CardHeader row={row} actions={headerAction} />
      )}
      {BodyComponent ? <BodyComponent row={row} /> : <CardBody row={row} />}
      {FooterComponent ? <FooterComponent row={row} /> : <> </>}
    </CardStyle>
  );
};
