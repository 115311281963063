// navigation.js
let globalClearAuth = null;
let globalToast = null;

export const setGlobalToast = toastFunction => {
  globalToast = toastFunction;
};
export const setGlobalClearAuth = navigateFunction => {
  globalClearAuth = navigateFunction;
};

export const useGlobalToast = (message) => {
  if (globalToast) {
    globalToast(message);
  } else {
    console.error('Toast function not initialized!');
  }
};
export const useClearAuth = () => {
  if (globalClearAuth) {
    globalClearAuth();
  } else {
    console.error('Navigate function not initialized!');
  }
};
