import React from 'react';
import {Form} from '../../../components/form';
import {useFormSubmit} from '../../../hooks';
import {routeLink} from '../../../constant';
import {useParams} from 'react-router-dom';

export const AddPatientDocuments = props => {
  const {onSubmit} = useFormSubmit({
    uri: routeLink.patientDocuments,
    eventSourceId: ['patient-documents'],
  });
  const {patientId} = useParams();
  return (
    <Form
      header={{title: 'Add Patient Documents'}}
      skipHeader={false}
      onSubmit={onSubmit}
      defaultValues={{
        patientId: patientId,
      }}
      layoutFields={[
        {
          field: 'name',
          type: 'text',
          required: true,
          label: 'Name',
        },
        {
          field: 'file',
          type: 'file',
          required: true,
          placeholder: 'Upload Document',
          inputProps: {
            accept: '.pdf,.svg,.jpeg,.jpg,.png',
          },
        },
      ]}
      {...props}
    />
  );
};
