import React, {useContext, useState} from 'react';
import {
  BrandName,
  FooterContainer,
  FormContainer,
  HeaderContainer,
  LayoutContainer,
  LoginMessage,
  ScreenContainer,
} from './LoginForm.style';
import {Form} from '../../../components/form';
import {LayoutFieldsRender} from '@unthinkable/react-form';
import {Button} from '../../../components/button';
import HttpAuth from '../../../services/HttpAuthService';
import {useToast} from '@unthinkable/react-toast';
import {onMFASuccess} from '../AuthUtils';
import {useNavigate} from 'react-router-dom';
import {AuthContext} from '../../../context/auth/AuthContext';
import buttonType from '../../../constant/buttonType';
import {Text, View} from '@unthinkable/react-core-components';
import {toasterMessage} from '../../../constant';
import Config from '../../../Config';

export const VerifyOtp = ({
  message,
  user,
  tokenId,
  isValidLink,
  mode,
  api,
  type,
  responseMessage,
  toastType,
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const {setAuth} = useContext(AuthContext);
  const navigate = useNavigate();
  const verify = async ({values}) => {
    values = {
      ...values,
      tokenId,
      type,
    };
    try {
      setLoading(true);
      const response = await HttpAuth.post(api, values);
      toast({
        message: mode
          ? toasterMessage.VerifyOtp.after2FA
          : toasterMessage.VerifyOtp.afterLogin,
        type: 'Success',
      });
      const resp = response.data;
      if (resp.isVerified) {
        if (!mode) {
          onMFASuccess({
            user: user,
            navigate,
            setAuth,
            toast,
            message: responseMessage,
            toastType,
          });
        } else {
          navigate('/login');
        }
      }
    } catch (err) {
      toast({
        message: err.message,
        type: 'Error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScreenContainer>
      <FormContainer>
        <HeaderContainer>
          <BrandName>{Config.brandName}</BrandName>
          {!mode || (mode && isValidLink) ? (
            <LoginMessage>{message}</LoginMessage>
          ) : (
            void 0
          )}
        </HeaderContainer>
        <Form
          defaultValues={{
            user,
          }}>
          {formContext => {
            const {values, isValid} = formContext;
            return (
              <LayoutContainer>
                <LayoutFieldsRender
                  layoutFields={[
                    {
                      render: () => {
                        return (
                          <View>
                            <Text style={{color: 'red', textAlign: 'center'}}>
                              {toasterMessage?.resetPassword?.invalidLink}
                            </Text>
                          </View>
                        );
                      },
                      visible: !(!mode || (mode && isValidLink)),
                    },
                    {
                      fields: [
                        {
                          type: 'text',
                          field: 'otp',
                          label: 'OTP',
                          required: true,
                          visible: isValidLink,
                          inputProps: {
                            autoComplete: 'off',
                          },
                          onKeyUp: async e => {
                            if (e.which === 13) {
                              await verify({values});
                            }
                          },
                        },
                      ],
                    },
                  ]}
                  {...formContext}
                />
                {!mode || (mode && isValidLink) ? (
                  <FooterContainer>
                    <Button
                      disabled={!isValid}
                      text="Verify"
                      onPress={async () => {
                        await verify({values});
                      }}
                      loading={loading}
                    />
                    {!mode && (
                      <Button
                        text="Reset Password?"
                        onPress={() => {
                          navigate('/forgot-password');
                        }}
                        buttonType={buttonType.linkButton}
                      />
                    )}
                  </FooterContainer>
                ) : (
                  void 0
                )}
              </LayoutContainer>
            );
          }}
        </Form>
      </FormContainer>
    </ScreenContainer>
  );
};
