import {FacilityAssignmentModal} from '../../../modules/tasks/screens/facilityAssignment.modal';
import {InitialEligibilityModal} from '../../../modules/tasks/screens/InitialEligibility.modal';
import {Form1823} from '../../../modules/tasks/screens/Form1823';
import {FormRejectReason} from '../../../modules/tasks/screens/formRejectReason';
import {AddContactAttemptsModal} from '../components/AddContactAttempts.modal';
import {CompleteVisitTask} from '../components/CompleteTCMVisit.modal';
import {TcmNotesModal} from '../components/TcmNotes.modal';
import CompleteMedicationModal from '../components/CompleteMedication.modal';
import {AddFacility} from '../components/AddRedoxFacility.modal';
import {completePatientClassificationTask} from '../components/CompletePatientClassificationTask.modal';
import {completeDischargeStatusTask} from '../components/CompleteDischargeStatus.modal';

export const TaskRoutes = [
  {
    name: 'Complete Visit',
    path: 'complete-visit/:taskId',
    component: CompleteVisitTask,
  },
  {
    name: 'Add Contact Attempts',
    path: ':EncounterId/:TaskId/add-contact-attempts',
    component: AddContactAttemptsModal,
    screenOptions: {
      modalSize: 'large',
    },
  },
  {
    name: 'TCM Notes',
    path: 'tcm-notes',
    component: TcmNotesModal,
    screenOptions: {
      modalSize: 'xlarge',
    },
  },
  {
    name: 'Complete Medication',
    path: 'complete-medication/:taskId',
    component: CompleteMedicationModal,
  },
  {
    name: 'Facility Assignment',
    path: 'facility-assignment',
    component: FacilityAssignmentModal,
    screenOptions: {
      modalSize: 'medium',
    },
    children: [
      {
        name: 'Add New Redox Facility From Facility Assignment',
        path: 'add-new-facility',
        component: AddFacility,
        screenOptions: {
          modalSize: 'medium',
        },
      },
    ],
  },
  {
    name: 'Initial TCM Eligibility',
    path: 'initial-eligibility',
    component: InitialEligibilityModal,
    screenOptions: {
      modalSize: 'medium',
    },
    children: [
      {
        name: 'Add New Redox Facility From Initial Eligibility',
        path: 'add-new-redox-facility',
        component: AddFacility,
        screenOptions: {
          modalSize: 'medium',
        },
      },
    ],
  },
  {
    name: 'Form 1823',
    path: 'form1823/:taskId',
    component: Form1823,
    children: [
      {
        name: 'Reject Reason',
        path: 'reject-reason',
        component: FormRejectReason,
        screenOptions: {
          modalSize: 'small',
        },
      },
    ],
  },
  {
    name: 'Patient Classification',
    path: 'patient-classification/:tcmTaskId',
    component: completePatientClassificationTask,
  },
  {
    name: 'Complete Discharge Status Task',
    path: 'complete-discharge-status/:taskId',
    component: completeDischargeStatusTask,
    children: [
      {
        name: 'Add New Redox Facility',
        path: 'add-new-facility',
        component: AddFacility,
        screenOptions: {
          modalSize: 'medium',
        },
      },
    ],
  },
];
