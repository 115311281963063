import React from 'react';
import TabNavigation from '../../../components/tabNavigation/TabNavigation';
import {OnboardingTasks} from '../../../constant/tcmTasks';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {TaskFilter} from '../../../modules/tasks/TaskFilter';
import {TodayTaskTable} from './TodayTaskTable';
import {TASK_TYPE} from '../../../constant/taskType';
import {TodayTasksContainer} from '../styles/Dashboard.style';
export const TodayTasks = () => {
  const {user} = useAuth();
  const {filter, taskTypes} = TaskFilter({user});
  const tcmTasks = [
    TASK_TYPE.ContactTask,
    TASK_TYPE.VisitTask,
    TASK_TYPE.MedicationTask,
    TASK_TYPE.NotesTask,
    TASK_TYPE.Form1823,
  ];
  const tcmTasksTypes = taskTypes.filter(type => tcmTasks.includes(type));
  const onboardingTaskTypes = taskTypes.filter(type =>
    OnboardingTasks.includes(type),
  );
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Start of the day
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1); // Start of the next day

  const tabs = [
    {
      name: 'All',
      element: TodayTaskTable,
      props: {
        filter: {...filter, type: {$in: taskTypes}},
        reduxKey: 'allTaskTable',
      },
    },
    {
      name: 'TCM Tasks',
      element: TodayTaskTable,
      props: {
        filter: {
          ...filter,
          type: {$in: tcmTasksTypes},
        },
        reduxKey: 'tcmTaskTale',
      },
    },
    {
      name: 'Onboarding',
      element: TodayTaskTable,
      props: {
        filter: {...filter, type: {$in: onboardingTaskTypes}},
        reduxKey: 'onboardingTaskTable',
      },
    },
  ];
  return (
    <TodayTasksContainer>
      <TabNavigation tabs={tabs} />
    </TodayTasksContainer>
  );
};
