import React, {useEffect} from 'react';
import View from '../View';
import {Portal} from '@unthinkable/react-portal';
import Styled from '../styled-components';
import {useIsMounted} from '@unthinkable/react-utils';

const StyledView = Styled(View)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index:10;
  background-color:${props =>
    props.hasBackdrop
      ? props?.backdropColor || 'rgba(0,0,0,0.2)'
      : 'transparent'}
`;

const Modal = ({
  isVisible,
  onModalHide,
  hasBackdrop = true,
  style,
  children,
}) => {
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isMounted() && !isVisible) {
      onModalHide?.();
    }
  }, [isVisible, isMounted, onModalHide]);

  if (!isVisible) {
    return null;
  }
  return (
    <Portal>
      <StyledView style={style} hasBackdrop={hasBackdrop}>
        {children}
      </StyledView>
    </Portal>
  );
};
export default Modal;
