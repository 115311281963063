import React, {useEffect, useState} from 'react';
import {View} from '@unthinkable/react-core-components';
import {TextRenderer} from '@unthinkable/react-text-input';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import images from '../../../assets/images';
import {TableHeader} from '../../../components/header/TableHeader';
import {BasicTable} from '../../../components/table/Table.js';
import Config from '../../../Config';
import {TCMContactStatus} from '../../../constant/status';
import {Icon} from '../../../components/icon';
import {HttpAuthService} from '../../../services';
import {routeLink} from '../../../constant';

const RenderAttemptCount = ({index, ...rest}) => {
  return <TextRenderer {...rest} value={`${index + 1} of 3`} />;
};

const RenderStatus = ({row}) => {
  if (row.contactStatus === TCMContactStatus.successful) {
    return (
      <Icon src={images.checkCircle} title={TCMContactStatus.successful} />
    );
  } else if (row.contactStatus === TCMContactStatus.unsuccessful) {
    return (
      <Icon src={images.closeCircle} title={TCMContactStatus.unsuccessful} />
    );
  } else {
    return null;
  }
};

export const ContactAttempts = props => {
  const {taskId} = props;
  const [contactAttemptList, setContactAttemptList] = useState([]);

  useDataFetchEvents({eventSourceId: 'contactAttempts'}, () => {
    getContactAttemptsList();
  });

  const getContactAttemptsList = async () => {
    if (!taskId) return;
    const response = await HttpAuthService.get(routeLink.getContactAttempt, {
      params: {
        filter: {
          taskId: taskId,
        },
        fields: {
          mode: {
            name: 1,
          },
          person: {
            name: 1,
          },
          updatedBy: {
            name: 1,
          },
          createdAt: {
            name: 1,
          },
          updatedAt: {
            name: 1,
          },
          date: 1,
          contactStatus: 1,
        },
        sort: {
          createdAt: 1,
        },
      },
    });
    setContactAttemptList(response.data);
  };

  useEffect(() => {
    getContactAttemptsList();
  }, []);
  return (
    <View style={{}}>
      <TableHeader title={'Contact Attempts'} />
      <BasicTable
        variant={'outline'}
        data={contactAttemptList}
        columns={[
          {
            header: 'Date',
            field: 'date',
            type: 'date',
            formatOptions: {
              format: Config.dateFormat,
            },
          },
          {
            header: 'Method',
            field: 'mode.name',
            type: 'text',
          },
          {
            header: 'Status',
            render: RenderStatus,
          },
          {
            render: RenderAttemptCount,
          },
        ]}
        {...props}
      />
    </View>
  );
};
