import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {View} from '@unthinkable/react-core-components';
import {TextRenderer} from '@unthinkable/react-text-input';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {BasicTable} from '../../../components/table/Table.js';
import routeLink from '../../../constant/routeLink.js';

import {Icon} from '../../../components/icon';
import images from '../../../assets/images';
import {HttpAuthService} from '../../../services';
import {RowHeading} from '../../../components/header/RowHeading';
import {invokeEvents} from '../../../hooks/useInvoke';
import {STATUS} from '../../../modules/user/constants/UserConstants';
import {generateCategoryWiseCareProviders} from '../../../services/user.service';
import {entity} from '../../../constant/authorization.js';

export const CareProviders = props => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const {state} = useLocation();
  const navigate = useNavigate();
  const {facility} = state || {};
  useEffect(() => {
    if (!facility) {
      navigate('/dashboard');
    }
  }, [facility]);
  const fetchCareProviders = async () => {
    setLoading(true);
    const response = await HttpAuthService.get(routeLink.getUser, {
      params: {
        filter: JSON.stringify({
          status: STATUS.ACTIVE,
          $or: [
            {facilityAssigned: facility?._id},
            {facilityId: facility?._id},
            {'practiceId.facilities': facility?._id},
          ],
        }),
        fields: {
          name: 1,
          userType: {
            name: 1,
          },
          practiceId: {
            name: 1,
          },
          facilityId: {
            name: 1,
          },
          employerType: {
            name: 1,
          },
          phoneNumber: 1,
          organizationType: 1,
        },
      },
    });
    setData(generateCategoryWiseCareProviders(response.data));
    setLoading(false);
  };

  useEffect(() => {
    fetchCareProviders();
  }, []);

  useDataFetchEvents(
    {eventSourceId: ['hospitalizationDiagnose']},
    fetchCareProviders,
  );

  const renderTable = props => {
    const {row} = props;
    const {notify} = useEventHandler();

    if (!row) return;
    if (row?.name === 'Header') {
      return (
        <View style={{flex: 1, overflow: 'hidden', maxHeight: '335px'}}>
          <BasicTable
            columns={[
              {
                header: 'Name',
              },
              {
                header: 'User Type',
              },
              {
                header: 'Phone Number',
              },
              {
                header: 'Employer',
              },
            ]}></BasicTable>
        </View>
      );
    } else
      return (
        <View style={{flex: 1}}>
          <RowHeading
            title={row.title}
            actions={() => {
              return (
                <Icon
                  src={row.expanded ? images.showLess : images.showMore}
                  onClick={() => {
                    row.expanded = !row.expanded;
                    invokeEvents({
                      eventSourceId: ['collapse'],
                      notify,
                    });
                  }}
                />
              );
            }}
          />
          {row.expanded && (
            <BasicTable
              eventSourceId={'careProviders'}
              variant={'outline'}
              data={row.data}
              columns={[
                {
                  field: 'name',
                  type: 'text',
                },
                {
                  type: 'text',
                  field: 'userType.name',
                },
                {
                  type: 'text',
                  field: 'phoneNumber',
                },
                {
                  render: ({row, ...rest}) => {
                    return (
                      <TextRenderer
                        {...rest}
                        title={
                          row?.employerType?.name === entity.facilities
                            ? row?.facilityId?.name
                            : row?.practiceId?.name
                        }
                        value={
                          row?.employerType?.name === entity.facilities
                            ? row?.facilityId?.name
                            : row?.practiceId?.name
                        }
                      />
                    );
                  },
                },
              ]}
              {...props}
            />
          )}
        </View>
      );
  };
  return (
    <BasicTable
      eventSourceId={['careProviders', 'collapse']}
      variant={'outline'}
      data={data}
      loading={loading}
      renderRow={renderTable}
      {...props}
    />
  );
};
