import React, {useState} from 'react';
import {ResponsiveModal, WithModal} from '@unthinkable/react-popper';
import {
  CancelButton,
  ConfirmButton,
  ConfirmContainer,
  FooterContainer,
  MessageText,
  SecondaryTitleText,
  TitleText,
} from './styles/ConfirmStyles';
import {useToast} from '@unthinkable/react-toast';

const ConfirmRender = ({
  styles,
  children,
  title,
  secondaryTitle,
  message,
  confirmText = 'Ok',
  cancelText = 'Cancel',
  onConfirm,
  onHide,
  variant,
  showConfirmButton = true,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  styles = {...styles, ...styles?.[variant]};
  if (typeof secondaryTitle === 'function') {
    secondaryTitle = secondaryTitle(props);
  }
  if (typeof message === 'function') {
    message = message(props);
  }

  return (
    <ConfirmContainer styles={styles}>
      {title ? <TitleText style={styles}>{title}</TitleText> : void 0}
      {secondaryTitle ? (
        <SecondaryTitleText style={styles}>{secondaryTitle}</SecondaryTitleText>
      ) : (
        void 0
      )}
      {message ? <MessageText style={styles}>{message}</MessageText> : void 0}
      <FooterContainer styles={styles}>
        <CancelButton
          styles={styles?.cancelButton}
          text={cancelText}
          onPress={() => {
            onHide && onHide();
          }}
        />
        {showConfirmButton && (
          <ConfirmButton
            styles={styles?.confirmButton}
            text={confirmText}
            loading={loading}
            onPress={async () => {
              try {
                setLoading(true);
                onConfirm && (await onConfirm(props));
                setLoading(false);
                toast({
                  type: 'Success',
                  message: 'Success',
                });
                onHide && onHide();
              } catch (err) {
                console.log(err);
                setLoading(false);
                toast({
                  type: 'Error',
                  message: err.message.length
                    ? err.message
                    : 'Internal Server Error',
                });
              }
            }}
          />
        )}
      </FooterContainer>
    </ConfirmContainer>
  );
};

export const ConfirmPopup = ({styles = {}, onHide, isVisible, ...props}) => {
  return (
    <ResponsiveModal
      position="screenCenter"
      styles={styles?.modal}
      isVisible={isVisible}
      hideModal={onHide}>
      <ConfirmRender {...props} styles={styles} onHide={onHide} />
    </ResponsiveModal>
  );
};

const Confirm = ({styles, children, onHide, disabled, ...props}) => {
  const renderModal = ({hide}) => {
    return (
      <ConfirmRender
        {...props}
        styles={styles}
        onHide={() => {
          hide();
          onHide && onHide();
        }}
      />
    );
  };

  return (
    <WithModal
      styles={styles?.modal}
      position="screenCenter"
      renderModal={renderModal}
      disabled={disabled}>
      {children}
    </WithModal>
  );
};

export default Confirm;
