import React from 'react';
import {useNavigate} from 'react-router-dom';
import {View} from '@unthinkable/react-core-components';
import {useTheme} from '@unthinkable/react-theme';
import {useFilter} from '@unthinkable/react-filters';
import {GroupRowText} from '@unthinkable/react-table/src/styles/TableStyles';
import images from '../../../assets/images';
import Config from '../../../Config';
import {ColumnTextRender} from '../styles/PracticeTable.style';
import {Icon} from '../../../components/icon';
import {useInvoke} from '../../../hooks';
import {
  SearchFilter,
  AutoCompleteFilter,
} from '../../../components/filter/Filters';
import {routeLink} from '../../../constant';
import {status} from '../../../constant/status';
import {Confirm} from '../../../components/confirm';
import buttonType from '../../../constant/buttonType';
import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {entity, action} from '../../../constant/authorization';
import checkAuthorization from '../../../services/accessService';
import {Table} from '../../../components/table';
import {TableHeader} from '../../../components/header/TableHeader';
import {Button} from '../../../components/button';
import modalMessages from '../../../constant/ModalMessage';
import {employerType} from '../../../constant/employerType';

const {defaultListLimit} = Config;

const RenderEdit = () => {
  return <Icon src={images.edit} />;
};

const RenderDelete = ({row}) => {
  const invoke = useInvoke({
    method: 'put',
    eventSourceId: 'practices',
    throwError: true,
  });

  if (row.status === status.inactive) {
    if (!row.primaryAdmin) {
      return (
        <Confirm
          title={modalMessages.activate.title}
          secondaryTitle={row.name}
          message={modalMessages.practice.adminNotSet}
          cancelText="Okay"
          showConfirmButton={false}>
          <Icon src={images.activation} />
        </Confirm>
      );
    } else if (!row.agreement) {
      return (
        <Confirm
          title={modalMessages.activate.title}
          secondaryTitle={row.name}
          message={modalMessages.practice.agreementNotSet}
          confirmText="Okay">
          <Icon src={images.activation} />
        </Confirm>
      );
    } else {
      return (
        <Confirm
          title={modalMessages.activate.title}
          secondaryTitle={row.name}
          message={`${modalMessages.activate.message} Practice`}
          confirmText="Confirm"
          onConfirm={async () => {
            await invoke({
              uri: routeLink.practiceLink + row._id,
              data: {status: status.active},
            });
          }}>
          <Icon src={images.activation} />
        </Confirm>
      );
    }
  }

  return (
    <Confirm
      title={modalMessages.deactivate.title}
      secondaryTitle={row.name}
      message={`${modalMessages.deactivate.message} Practice`}
      confirmText="Confirm"
      onConfirm={async () => {
        await invoke({
          uri: routeLink.practiceLink + row._id,
          data: {status: status.inactive},
        });
      }}>
      <Icon src={images.trash} />
    </Confirm>
  );
};

const RenderAdmin = ({row}) => {
  return (
    <View>
      <ColumnTextRender> {row?.primaryAdmin?.name} </ColumnTextRender>
      <GroupRowText> {row?.primaryAdmin?.email} </GroupRowText>
    </View>
  );
};

export const PracticeTable = props => {
  const navigate = useNavigate();
  const {searchValue, onChangeFilter, filterValues} = useFilter();
  const {user} = useAuth();
  const {AddPractice} = useTheme('icons');

  if (user?.employerType?.name === employerType.practice) {
    filterValues.filter = {
      ...filterValues.filter,
      _id: user?.practiceId?._id,
    };
  }

  return (
    <Table
      noDataText={'No practices found'}
      renderHeader={() => (
        <TableHeader
          actions={[
            <SearchFilter
              value={searchValue}
              onChangeFilter={onChangeFilter}
              key={'SearchFilter'}
              placeholder={'Search practice'}
            />,
            <AutoCompleteFilter
              onChangeFilter={onChangeFilter}
              filterValues={filterValues}
              field={'status'}
              options={[status.active, status.inactive]}
              key={'statusFilter'}
              placeholder={'Status'}
            />,
            checkAuthorization(user, entity.practices, action.create) ? (
              <Button
                key="addPractice"
                text="Create New Practice"
                onPress={() => {
                  navigate('/practices/add');
                }}
                icon={AddPractice}
                iconPosition="right"
                buttonType={buttonType.linkButtonWithBorder}
              />
            ) : null,
          ]}
        />
      )}
      search={searchValue}
      sort={{name: 1}}
      searchFields={['name']}
      api={routeLink.practiceLink}
      eventSourceId="practices"
      addOnFilter={JSON.stringify(filterValues.filter)}
      perPage={defaultListLimit}
      fields={{
        name: 1,
        primaryAdmin: {
          name: 1,
          email: 1,
        },
        phoneNumber: 1,
        faxNumber: 1,
        status: 1,
        agreement: 1,
      }}
      columns={[
        {
          header: 'Name',
          field: 'name',
          type: 'text',
        },
        {
          header: 'Primary Admin',
          render: RenderAdmin,
        },
        {
          header: 'Phone No.',
          field: 'phoneNumber',
          type: 'text',
        },
        {
          header: 'Fax No.',
          field: 'faxNumber',
          type: 'text',
        },
        {
          header: 'Status',
          field: 'status',
          type: 'text',
        },
        {
          render: RenderEdit,
          onPress: ({row}) => {
            navigate('/practices/edit/' + row?._id, {
              state: {
                practice: row,
                titleName: row.name,
              },
            });
          },
          width: 50,
          visible: () =>
            checkAuthorization(user, entity.practices, action.edit),
        },
        {
          render: RenderDelete,
          visible: () =>
            checkAuthorization(user, entity.practices, action.delete),
          width: 50,
        },
      ]}
      {...props}
    />
  );
};
