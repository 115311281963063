import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Text, View} from '@unthinkable/react-core-components';
import {routeLink, toasterMessage} from '../../../constant';
import HttpAuth from '../../../services/HttpAuthService';
import {useToast} from '@unthinkable/react-toast';
import {
  BrandName,
  FooterContainer,
  FormContainer,
  HeaderContainer,
  LayoutContainer,
  LoginMessage,
  ScreenContainer,
} from './LoginForm.style';
import {LayoutFieldsRender} from '@unthinkable/react-form';
import {Button} from '../../../components/button';
import {Form} from '../../../components/form';
import {AuthenticatorSetup} from './AuthenticatorSetup';
import {SwitchInput} from '@unthinkable/react-switch';
import {UserService} from '../../../services';
import Config from '../../../Config';
const AuthenticatorSetupComponent = user => {
  return <AuthenticatorSetup userData={user} />;
};
const TwoFactorAuthenticationForm = () => {
  const navigate = useNavigate();
  let label = {
    title: 'Enable Two Factor Authentication',
    buttonText: 'Enable',
  };
  const {state} = useLocation();
  const {user, token, type} = state;
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [isValidLink, setIsValidLink] = useState(true);
  const [tokenId, setTokenId] = useState(null);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const data = await UserService.verifyResetPasswordToken(token, type);
        if (data?.success) {
          setIsValidLink(true);
          setTokenId(data.data);
        } else {
          setIsValidLink(false);
          setTokenId(null);
        }
      } catch (error) {
        setIsValidLink(false);
        setTokenId(null);
      }
    };
    verifyToken();
  }, []);

  return (
    <ScreenContainer>
      <FormContainer>
        <HeaderContainer>
          <BrandName>{Config.brandName}</BrandName>
          <LoginMessage>{label.title}</LoginMessage>
        </HeaderContainer>
        <Form>
          {formContext => {
            const {values} = formContext;
            return (
              <LayoutContainer>
                <LayoutFieldsRender
                  layoutFields={[
                    {
                      render: () => {
                        return (
                          <View>
                            <Text style={{color: 'red', textAlign: 'center'}}>
                              {toasterMessage?.resetPassword?.invalidLink}
                            </Text>
                          </View>
                        );
                      },
                      visible: !isValidLink,
                    },
                    {
                      fields: [
                        {
                          type: 'autoComplete',
                          field: 'mfaMode',
                          options: ['Authenticator App', 'Email'],
                          placeholder: 'Please select an option to enable 2FA.',
                          onChangeValue: (value, e, {setFieldValue}) => {
                            setFieldValue(
                              'authRequired',
                              value === 'Authenticator App',
                            );
                          },
                          required: true,
                          visible: isValidLink,
                        },
                        {
                          render: () => AuthenticatorSetupComponent(user),
                          visible: ({values}) => values.authRequired,
                        },
                        {
                          type: 'text',
                          label: 'OTP',
                          field: 'otp',
                          size: 8,
                          visible: ({values}) => values.authRequired,
                        },
                        {
                          render: (e, {values, setFieldValue}) => (
                            <Button
                              text="Submit"
                              loading={loading}
                              onPress={async () => {
                                try {
                                  setLoading(true);
                                  const response = await HttpAuth.post(
                                    routeLink.verifyAuthenticatorOtpAfterPasswordReset,
                                    {
                                      otp: values.otp,
                                      user: user,
                                      tokenId,
                                      type,
                                    },
                                  );
                                  const resp = response.data;
                                  if (resp.isVerified) {
                                    setFieldValue('isVerified', true);
                                    toast({
                                      message: `${toasterMessage?.enableTwoFactor.success}`,
                                      type: 'Success',
                                    });
                                    navigate('/login');
                                  } else {
                                    console.log(response?.data?.error);
                                    toast({
                                      message:
                                        response?.message ||
                                        `${toasterMessage?.enableTwoFactor.failure}`,
                                      type: 'Error',
                                    });
                                  }
                                } catch (err) {
                                  toast({
                                    message: `there is error ${err.message}`,
                                    type: 'Error',
                                  });
                                } finally {
                                  setLoading(false);
                                }
                              }}
                            />
                          ),
                          size: 4,
                          visible: ({values}) => values.authRequired,
                        },
                        {
                          render: SwitchInput,
                          field: 'isVerified',
                          label: 'Verification Status',
                          readOnly: true,
                          required: true,
                          visible: ({values}) => values.authRequired,
                        },
                      ],
                    },
                  ]}
                  {...formContext}
                />
                {values?.mfaMode === 'Email' && (
                  <FooterContainer>
                    <Button
                      text={label.buttonText}
                      loading={loading}
                      onPress={async () => {
                        navigate('/mfa-email', {
                          state: {user, mode: 'Email', token, type},
                        });
                      }}
                    />
                  </FooterContainer>
                )}
              </LayoutContainer>
            );
          }}
        </Form>
      </FormContainer>
    </ScreenContainer>
  );
};

export default TwoFactorAuthenticationForm;
