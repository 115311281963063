import {View} from '@unthinkable/react-core-components';
import React from 'react';
import {useFormSubmit} from '../../../hooks';
import {Form} from '../../../components/form';
import {Button} from '../../../components/button';
import {routeLink} from '../../../constant';
import {useLocation, useNavigate} from 'react-router-dom';
import {addMedicationStyle} from '../../../modules/patient/screens/style/AddMedication.style';
import images from '../../../assets/images';
import buttonType from '../../../constant/buttonType';

export const InitialEligibilityModal = props => {
  const {
    state: {tcmTaskId, patient},
  } = useLocation();
  const {onSubmit} = useFormSubmit({
    uri: `${routeLink.CompleteInitialEligibilityTask}`,
    method: 'put',
    eventSourceId: ['tcmtasks', 'initialTcmEligibility'],
  });

  const RenderPlusButton = () => {
    const navigate = useNavigate();
    return (
      <View style={addMedicationStyle.buttonContainer}>
        <Button
          key="Add New Facility Form Transitional Event"
          icon={images.addIcon}
          buttonType={buttonType.linkButtonWithBorder}
          onPress={() => {
            navigate('add-new-redox-facility', {
              state: {
                tcmTaskId,
                patient,
              },
            });
          }}
        />
      </View>
    );
  };

  return (
    <Form
      saveButtonText={'Save'}
      onSubmit={onSubmit}
      defaultValues={{
        status: 'Complete',
        successStatus: true,
        tcmTaskId: tcmTaskId,
        patientId: patient?._id,
      }}
      layoutFields={[
        {
          label: `Initial TCM Eligibility-${patient?.FullName}`,
          fields: [
            {
              type: 'autoComplete',
              label: 'Initial TCM Eligibility',
              options: [
                {label: 'Yes', value: true},
                {label: 'No', value: false},
              ],
              field: 'isTcmEligible',
              sort: {name: 1},
              keyField: 'value',
              suggestionField: 'label',
              required: true,
              size: 'medium',
            },
            {
              type: 'date',
              field: 'dischargeDate',
              label: 'Discharge Date',
              size: 'medium',
              required: true,
              visible: ({values}) => values?.isTcmEligible,
            },
            {
              type: 'autoComplete',
              label: 'Location',
              api: routeLink.getFacilities,
              field: 'location',
              sort: {name: 1},
              suggestionField: 'name',
              required: true,
              size: 'medium',
              visible: ({values}) => values?.isTcmEligible,
            },
            {
              render: RenderPlusButton,
              size: 0.5,
              visible: ({values}) => values?.isTcmEligible,
            },
          ],
        },
      ]}
      {...props}
    />
  );
};
