export const buttonStyles = ({theme: {colors, fonts}}) => {
  return {
    container: {
      backgroundColor: colors.BUTTON_NEUTRAL_HIGH,
      padding: '12px 18px',
      borderRadius: '8px',
      transition: 'all .2s ease-in',
      '&:hover': {backgroundColor: colors.SAVE_BUTTON_HOVER_BACKGROUND_COLOR},
    },
    disabledContainer: {
      backgroundColor: colors.OUTLINE,
      '&:hover': {backgroundColor: colors.OUTLINE},
    },
    text: {
      ...fonts.ACTION,
      color: colors.BACKGROUND,
      fontWeight: 700,
    },
    disabledText: {color: colors.NEUTRAL_LOW},
  };
};

export const linkButtonStyles = ({theme: {colors, fonts}}) => {
  return {
    container: {
      backgroundColor: colors.BACKGROUND,
      padding: '12px 18px',
      borderRadius: '8px',
    },
    disabledContainer: {
      backgroundColor: colors.OUTLINE,
    },
    text: {
      ...fonts.ACTION,
      color: colors.BUTTON_NEUTRAL_HIGH,
      fontWeight: 700,
      transition: 'all .2s ease-in',
      '&:hover': {
        color: colors.NEUTRAL_HIGH,
      },
    },
    disabledText: {color: colors.NEUTRAL_LOW},
  };
};

export const LinkButtonWithBorder = ({theme: {colors, fonts}}) => {
  return {
    container: {
      backgroundColor: colors.BACKGROUND,
      padding: '8px 16px',
      borderRadius: '8px',
      border: `1px solid ${colors.BUTTON_TEXT}`,
      transition: 'all .2s ease-in',
      '&:hover': {backgroundColor: colors.CANCEL_BUTTON_HOVER_BACKGROUND_COLOR},
    },
    disabledContainer: {
      backgroundColor: colors.OUTLINE,
      '&:hover': {backgroundColor: colors.OUTLINE},
    },
    text: {
      ...fonts.ACTION,
      color: colors.BUTTON_TEXT,
      fontWeight: 700,
      transition: 'all .2s ease-in',
      '&:hover': {color: colors.NEUTRAL_HIGH},
    },
    disabledText: {color: colors.NEUTRAL_LOW},
  };
};
