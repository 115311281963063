import React from 'react';
import {ChatHeaderMainDiv, MemberIconStyle} from '../styles/ChatHeader.style';
import images from '../../../assets/images';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {getGroupName} from '../utils/ChatHelperFunctions';
const ChatHeader = ({group}) => {
  const navigate = useNavigate();
  const {channelId, groupId} = useParams();
  const location = useLocation();
  return (
    <ChatHeaderMainDiv>
      {decodeURIComponent(getGroupName(group))}
      <MemberIconStyle
        src={images.groupMembers}
        alt="members"
        onClick={() => {
          if (location.pathname.split('/')[1] === 'patient') {
            navigate(`/patient/chat/${channelId}/${groupId}/GroupMembersList`, {
              state: {
                groupName: group?.name,
                patientId: true,
              },
            });
          }else if(location.pathname.split('/')[1] === 'patientgroups') {
            navigate(`/patientgroups/chat/${channelId}/${groupId}/GroupMembersList`, {
              state: {
                groupName: group?.name,
                patientId: true,
              },
            });
          }
           else {
            navigate(`/groups/chat/${channelId}/${groupId}/GroupMembersList`, {
              state: {
                groupName: group?.name,
              },
            });
          }
        }}
      />
    </ChatHeaderMainDiv>
  );
};

export default ChatHeader;
