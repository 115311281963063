import {useEffect, useState} from 'react';
import {
  useFilterKeyValues,
  useFilterContext,
} from '../providers/FilterProvider';
import {populateFilter} from '../util';

const useFilterBase = ({
  filterKey,
  filter: defaultFilter = {},
  params: defaultParams = {},
  plainFilter = {},
} = {}) => {
  const {setFilter, clearFilter, clearSearchValue} = useFilterContext();
  let filter = useFilterKeyValues(filterKey);

  const [localFilter, setLocalFilter] = useState({
    filterValues: {filter: defaultFilter, params: defaultParams, plainFilter},
  });
  // if there is any initial filter passed and there is not any filter in context then return initial passed filter or local filter
  filter =
    Object.keys(filter?.filterValues?.filter || {}).length ||
    Object.keys(filter?.filterValues?.params || {}).length ||
    Object.keys(filter?.filterValues?.plainFilter || {}).length
      ? filter
      : localFilter;

  useEffect(() => {
    setFilter(filterKey, {
      filterValues: filter?.filterValues || {
        filter: defaultFilter,
        params: defaultParams,
        plainFilter,
      },
    });
  }, [filterKey]);

  const resetFilter = () => {
    filterKey
      ? setFilter(filterKey, {
          filterValues: {
            filter: defaultFilter,
            params: defaultParams,
            plainFilter,
          },
        })
      : setLocalFilter({
          filterValues: {
            filter: defaultFilter,
            params: defaultParams,
            plainFilter,
          },
        });
  };

  const applyFilter = filterInfo => {
    filterKey ? setFilter(filterKey, filterInfo) : setLocalFilter(filterInfo);
  };

  const onChangeFilter = props => {
    let filterInfo = filterKey ? {...filter} : {...localFilter};
    filterInfo = populateFilter(filterInfo, props);
    filterKey ? setFilter(filterKey, filterInfo) : setLocalFilter(filterInfo);
  };

  return {
    filterValues: {params: {}, filter: {}, plainFilter: {}},
    ...(filterKey ? filter : localFilter),
    onChangeFilter,
    applyFilter,
    resetFilter,
    clearSearch: clearSearchValue,
  };
};

export const useFilter = (props = {}) => {
  return useFilterBase({
    filterKey: window.location.pathname,
    ...props,
  });
};
