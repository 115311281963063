import React from 'react';
import {ActivityIndicator} from 'react-native-web';
import {FlatList} from '@unthinkable/react-core-components';
import Message from './Message';
import {ChatActivityIndicatorStyle} from '../styles/Chat.style';
import {getTimeFromTimeToken} from '../utils/ChatHelperFunctions';
const ChatMessageArea = ({
  messages,
  chatElement,
  loadMore,
  isLoading,
  membersData,
  setCanJump,
}) => {
  if (isLoading) {
    return (
      <ActivityIndicator size="large" style={ChatActivityIndicatorStyle} />
    );
  }
  return (
    <FlatList
      keyExtractor={item => item.timetoken}
      loading={isLoading}
      ref={chatElement}
      data={messages}
      onScroll={e => {
        const {scrollTop, clientHeight, scrollHeight} = e;
        if (scrollTop < scrollHeight - clientHeight) {
          setCanJump && setCanJump(true);
        } else if (scrollTop >= scrollHeight - clientHeight) {
          setCanJump && setCanJump(false);
        }
        if (scrollTop == 0) {
          loadMore && loadMore();
        }
      }}
      renderItem={({item}) => {
        return (
          <div>
            <Message
              text={item?.text}
              sender={membersData?.[item?.uuid]}
              senderId={item.uuid}
              date={getTimeFromTimeToken(item.timetoken)}
            />
          </div>
        );
      }}
    />
  );
};

export default ChatMessageArea;
