import React from 'react';
import {Row, View, Text} from '@unthinkable/react-core-components';
import {Icon} from '../components/icon';
import images from '../assets/images';
import {useAuth} from '../modules/auth/hooks/useAuth';
export const ActionPopupTable = () => {
  const {logout} = useAuth();
  return (
    <View>
      <Row
        onClick={async () => {
          await logout();
        }}
        style={{
          paddingTop: 4,
          paddingBottom: 4,
          gap: 10,
          alignItems: 'center',
        }}>
        <Text>Logout</Text>
        <Icon src={images.logout} alt="logout"></Icon>
      </Row>
    </View>
  );
};
