import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useToast} from '@unthinkable/react-toast';
import {action, entity} from '../constant/authorization';
import {TASK_STATUS, TASK_TYPE, Tcm_TASKS} from '../constant/taskType';
import checkAuthorization from './accessService';
import {Icon} from '../components/icon';
import images from '../assets/images';
import modalMessages from '../constant/ModalMessage';
import {isFacilityAdmin} from '../utils/checkUserType';
import {useAuth} from '../modules/auth/hooks/useAuth';
import {RenderGenerateNote} from '../modules/tasks/screens/RenderNotesTask';

export const TaskAction = task => {
  // for tcm tasks
  const IsFacilityAdmin = isFacilityAdmin();
  const toast = useToast();
  const {user} = useAuth();
  const navigate = useNavigate();
  if (task?.type === TASK_TYPE.NotesTask) {
    return RenderGenerateNote({
      row: task,
      route: 'tcm-notes',
      navigate,
      toast,
      user,
    });
  }
  if (
    (Tcm_TASKS[task?.type] &&
      checkAuthorization(user, entity.TcmTasks, action.complete)) ||
    checkAuthorization(user, entity[task?.type], action.complete)
  ) {
    const onPress = e => {
      if (task.type === TASK_TYPE.ContactTask) {
        navigate(
          `${task.encounterId._id}/${task.taskId}/add-contact-attempts`,
          {
            state: {
              encounter: task?.encounterId,
              patient: task?.patientId,
            },
          },
        );
      } else if (task.type === TASK_TYPE.VisitTask) {
        navigate(`complete-visit/${task.taskId}`, {
          state: {
            encounter: task?.encounterId,
            patient: task?.patientId,
          },
        });
      } else if (task.type === TASK_TYPE.MedicationTask) {
        navigate(`medication-task`, {
          state: {
            encounter: task?.encounterId,
            patient: task?.patientId,
            medicationId: task?.taskId,
          },
        });
      } else if (task.type === TASK_TYPE.INITIAL_TCM_ELIGIBILITY) {
        navigate('initial-eligibility', {
          state: {
            status: task.status,
            type: task.type,
            encounter: task?.encounterId,
            patient: task?.patientId,
            tcmTaskId: task?._id,
          },
        });
      } else if (task.type === TASK_TYPE.FACILITY_ASSIGNMENT) {
        navigate('facility-assignment', {
          state: {
            status: task.status,
            type: task.type,
            encounter: task?.encounterId,
            tcmTaskId: task?._id,
            patient: task?.patientId,
          },
        });
      } else if (task.type === TASK_TYPE.FACILITY_ONBOARDING) {
        navigate(`facility-onboarding/${task?.facilityId?._id}`, {
          state: {
            facility: task?.facilityId,
            status: task.status,
            type: task.type,
            tcmTaskId: task?._id,
          },
        });
      } else if (task.type === TASK_TYPE.Form1823) {
        if (IsFacilityAdmin && task.status !== TASK_STATUS.Approved) {
          toast({
            type: 'Error',
            message:
              task.status === TASK_STATUS.Active
                ? modalMessages.form1823.active
                : modalMessages.form1823.rejected,
          });
        } else {
          navigate(`form1823/${task.taskId}`, {
            state: {
              status: task.status,
              type: task.type,
              encounter: task?.encounterId,
            },
          });
        }
      } else if (task.type === TASK_TYPE.PATIENT_CLASSIFICATION) {
        console.log('patientCLassification');
        navigate(`patient-classification/${task?._id}`, {
          state: {
            status: task.status,
            type: task.type,
            encounter: task?.encounterId,
            tcmTaskId: task?._id,
            patient: task?.patientId,
          },
        });
      } else if (task.type === TASK_TYPE.DISCHARGE_STATUS) {
        navigate(`complete-discharge-status/${task?._id}`, {
          state: {
            encounter: task?.encounterId,
            patient: task?.patientId,
          },
        });
      }
      e.stopPropagation();
    };
    return <Icon src={images.activation} onClick={onPress} />;
  }
};
