import React, {useEffect, useState} from 'react';
import {HospitalizationMedication} from './HospitalizationMedicationTable';
import {useLocation} from 'react-router-dom';
import {patientDetailStyles} from '../screens/style';
import {View} from '@unthinkable/react-core-components';
import {HospitalizationDiagnose} from './HospitalizationDiagnoseTable';
import {HttpAuthService} from '../../../services';
import {routeLink} from '../../../constant';
import {useDataFetchEvents} from '@unthinkable/react-data-handler';
import {TableHeader} from '../../../components/header/TableHeader';
import {getPatientsFullName} from '../../../utils/commonMethod';
const CompleteMedication = () => {
  const {encounter, patient, medicationId} = useLocation().state;
  const [isTcmMedicationButtonVisible, setIsTcmMedicationButtonVisible] =
    useState(false);
  const fetchMedicationTask = async medicationId => {
    const response = await HttpAuthService.get(routeLink.getTCMTask, {
      params: {
        filter: {
          taskId: medicationId,
        },
      },
    });
    if (response?.data && response?.data[0]?.status === 'Active') {
      setIsTcmMedicationButtonVisible(true);
    } else if (response?.data && response?.data[0]?.status !== 'Active') {
      setIsTcmMedicationButtonVisible(false);
    }
  };

  useEffect(() => {
    fetchMedicationTask(medicationId);
  }, []);

  useDataFetchEvents({eventSourceId: ['MedicationTask']}, () => {
    fetchMedicationTask(medicationId);
  });

  return (
    <View style={patientDetailStyles.styles.container}>
      <TableHeader title={`Medication Task -${getPatientsFullName(patient)}`} />
      <HospitalizationMedication
        encounter={encounter}
        patient={patient}
        isTcmMedicationButtonVisible={isTcmMedicationButtonVisible}
        entity={'TCMTASK'}
        medicationId={medicationId}
      />
      <HospitalizationDiagnose encounter={encounter} />
    </View>
  );
};

export default CompleteMedication;
