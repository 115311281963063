import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import moment from 'moment';
import {Row, View} from '@unthinkable/react-core-components';
import {useEventHandler} from '@unthinkable/react-event-handler';
import {useToast} from '@unthinkable/react-toast';
import {TextRender} from '@unthinkable/react-table';

import {useAuth} from '../../../modules/auth/hooks/useAuth';
import {TaskWidget} from '../../../modules/patient/components/TaskWidget';
import {useMediaQuery} from '../../../modules/auth/hooks/useMediaquery';
import {patientDetailStyles} from '../../../modules/patient/screens/style';
import {
  Patient_Location_Type,
  Patient_Type,
} from '../../../modules/patient/constants/PatientConstants';
import {Table} from '../../../components/table';
import {Button} from '../../../components/button';
import {Confirm} from '../../../components/confirm';
import {TableHeader} from '../../../components/header/TableHeader';
import {TextRenderer} from '../../../components/formEditors/Editors';
import {TaskCardFooter} from '../../../components/card/taskCard.style';
import {renderPatientCard} from '../../../components/card/patientCard';
import {status} from '../../../constant/status';
import {routeLink} from '../../../constant';
import buttonType from '../../../constant/buttonType';
import modalMessages from '../../../constant/ModalMessage';
import {TASK_TYPE} from '../../../constant/taskType';
import {patientFields} from '../../../constant/patient.fields';
import {action, entity} from '../../../constant/authorization';
import {ENCOUNTER_STATUS} from '../../../constant/encounterStatus';
import checkAuthorization from '../../../services/accessService';
import {HttpAuthService} from '../../../services';
import Config from '../../../Config';
import {getPatientsFullName} from '../../../utils/commonMethod';
import {isPracticeEmployee} from '../../../utils/checkEmployerType';
import {useInvoke} from '../../../hooks';
import {Icon} from '../../../components/icon';
import images from '../../../assets/images';
import {ColumnTextRender} from '../../tasks/styles/TaskTable.style';
import {FACILITY_TYPE} from '../../../constant/facilityType';

const getDifference = tcmDueDate => {
  let hours = (new Date(tcmDueDate) - new Date()) / 36e5; // 36e5 is 1 hour in milliseconds
  if (hours <= 0) {
    return 'Overdue';
  } else if (hours > 0 && hours < 24) {
    return 'Red';
  } else if (hours >= 24 && hours < 72) {
    return 'Yellow';
  } else if (hours >= 72) {
    return 'Gray';
  }
};

const TcmDueDate = ({row}) => {
  if (
    !row?.latestEncounter ||
    row?.latestEncounter?.status === ENCOUNTER_STATUS.BILLABLE ||
    row?.latestEncounter?.status === ENCOUNTER_STATUS.INCOMPLETE
  ) {
    return;
  } else if (row?.latestEncounter?.onGoingTcmDueDate) {
    return (
      <ColumnTextRender
        type={getDifference(row?.latestEncounter?.onGoingTcmDueDate)}>
        {moment(row?.latestEncounter?.onGoingTcmDueDate)
          .utc()
          .format(`${Config.dateFormat}`)}
      </ColumnTextRender>
    );
  }
};

export const YourPatientsTable = () => {
  const {user} = useAuth();
  const navigate = useNavigate();
  const {state} = useLocation();
  const {
    filter: stateFilter = {},
    field,
    sort,
    api,
    redoxKey,
    showPatientType,
    admissionPlanningTab,
    transitionalCareTab = false,
  } = state || {};

  useEffect(() => {
    if (!api || !redoxKey) {
      navigate('/dashboard');
    }
  }, []);
  const [loading, setLoading] = useState(false);
  const {isMobile} = useMediaQuery();
  const filter = {...stateFilter};
  const {notify} = useEventHandler();
  const toast = useToast();

  const invoke = useInvoke({method: 'put', throwError: true});

  const requestForm1823Handler = async (patient, index) => {
    setLoading(index);
    try {
      const response = await HttpAuthService.post(
        `${routeLink.CreateForm1823Task}`,
        {
          patientId: patient?._id,
          encounterId: patient?.latestEncounter?._id,
        },
      );
      if (response?.success) {
        notify &&
          notify('dataUpdated', {
            id: 'form1823Task',
          });
      }
      toast({
        message: response.message,
        type: response.success ? 'Success' : 'Error',
      });
    } catch (err) {
      toast({
        message: err.message,
        type: 'Error',
      });
    } finally {
      setLoading(-1);
    }
  };

  const RenderPatientTypeChangeAction = patient => {
    return (
      <Confirm
        title={modalMessages.changeToSkilledNursing.title}
        secondaryTitle={patient.name}
        message={
          patient?.type === 'skilledNursing'
            ? modalMessages.changeToLongTermCare.message
            : modalMessages.changeToSkilledNursing.message
        }
        confirmText="Confirm"
        onConfirm={async () => {
          await invoke({
            uri: routeLink.getPatient + '/' + patient?._id,
            data: {
              type:
                patient?.type === 'skilledNursing'
                  ? 'longTermCare'
                  : 'skilledNursing',
            },
            eventSourceId: ['patientClassification'],
          });
        }}>
        <Icon src={images.change} />
      </Confirm>
    );
  };

  if (checkAuthorization(user, entity.yourPatient, action.viewAll)) {
    if (isPracticeEmployee()) {
      filter['$and'] = [
        ...(filter['$or'] ? [{$or: [...filter['$or']]}] : []),
        {
          $or: [
            {primaryPractice: user?.practiceId?._id},
            {practices: user?.practiceId?._id},
          ],
        },
      ];
      if (filter['$or']) delete filter['$or'];
    }
    const FooterComponent = ({row}) => {
      return (
        <TaskCardFooter>
          <TaskWidget
            patient={row}
            encounter={row?.latestEncounter}
            skipMessage
          />
        </TaskCardFooter>
      );
    };
    let columns = [];
    if (isMobile) {
      columns = [
        {
          render: ({row}) => {
            return renderPatientCard({row, FooterComponent});
          },
        },
      ];
    } else {
      columns = [
        {
          header: 'Name',
          type: 'text',
          render: ({row}) => {
            const {FirstName, LastName, MiddleName} = row;
            return getPatientsFullName({FirstName, LastName, MiddleName});
          },
          width: 250,
        },
        {
          header: 'Residence',
          render: ({row}) => {
            const text =
              row?.residenceType?.value == Patient_Location_Type.Home
                ? Patient_Location_Type.Home
                : row?.residence?.name;
            return <TextRenderer value={text} />;
          },
          type: 'text',
          visible: !transitionalCareTab,
          width: 300,
        },
        {
          header: 'Current Location',
          render: ({row}) => {
            const text =
              row?.currentLocationType?.value == Patient_Location_Type.Home
                ? Patient_Location_Type.Home
                : row?.currentLocation?.name;
            return <TextRenderer value={text} />;
          },
          type: 'text',
          visible: !transitionalCareTab,
          width: 300,
        },
        {
          header: 'Discharge Date',
          render: ({row}) => {
            return row?.latestEncounter?.dischargeDate ? (
              <TextRender
                value={moment(row?.latestEncounter?.dischargeDate)
                  .utc()
                  .format(Config.dateFormat)}
              />
            ) : (
              ''
            );
          },
          width: 200,
          visible: transitionalCareTab,
        },
        {
          header: 'Readmission Risk',
          render: () => {
            return '';
          },
          visible: transitionalCareTab,
          width: 200,
        },
        {
          header: 'TCM Due Date',
          render: props => {
            return (
              <Row
                gap={36}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <TcmDueDate {...props} />
              </Row>
            );
          },
          width: 150,
          visible: !admissionPlanningTab,
        },
        {
          header: 'Type',
          render: ({row}) => {
            return <TextRenderer value={Patient_Type[row?.type]} />;
          },
          visible: () => showPatientType,
          width: 150,
        },
        {
          render: ({row}) => {
            const activeTasks = row?.latestEncounter?.taskInfo?.filter(
              task =>
                task.status === status.active || task.status === status.reject,
            );
            if (
              activeTasks?.length &&
              row?.primaryPractice?._id === user?.practiceId?._id
            ) {
              return (
                <TaskWidget
                  patient={row}
                  encounter={row?.latestEncounter}
                  skipMessage
                />
              );
            }
          },
          width: 600,
          visible: () => {
            return user?.practiceId && !admissionPlanningTab;
          },
        },
        {
          render: ({row, index}) => {
            const form1823 = row?.latestEncounter?.taskInfo?.filter(
              task => task.type === TASK_TYPE.Form1823,
            );
            return (
              !form1823?.length && (
                <Confirm
                  title={modalMessages.requestForm1823.title}
                  message={modalMessages.requestForm1823.message}
                  confirmText={modalMessages.requestForm1823.confirmText}
                  onConfirm={() => {
                    requestForm1823Handler(row, index);
                  }}>
                  <Button
                    key="Request Form 1823"
                    text="Request Form 1823"
                    buttonType={buttonType.linkButton}
                  />
                </Confirm>
              )
            );
          },
          visible: () => {
            return (
              transitionalCareTab &&
              checkAuthorization(user, entity.form1823, action.request)
            );
          },
        },
        {
          render: ({row}) => RenderPatientTypeChangeAction(row),
          visible: () => {
            return (
              showPatientType &&
              checkAuthorization(user, entity.patientType, action.edit)
            );
          },
          width: 50,
        },
      ];
    }
    return (
      <Table
        renderHeader={() => {
          return (
            <TableHeader
              actions={
                admissionPlanningTab &&
                checkAuthorization(user, entity.patient, action.create)
                  ? [
                      <Button
                        key="Add Patient"
                        text="Add Patient"
                        onPress={() => {
                          navigate('/facility-stats/overview/add-patient', {
                            state,
                          });
                        }}
                        icon={images.addIcon}
                        iconPosition="right"
                        buttonType={buttonType.linkButtonWithBorder}
                      />,
                    ]
                  : []
              }
            />
          );
        }}
        flex={false}
        redoxKey={redoxKey}
        api={api}
        filter={JSON.stringify(filter)}
        onRowPress={({row}) => {
          navigate(`/patient/details/${row?._id}/careTeamMembers`, {
            state: {
              patient: row,
              titleName: getPatientsFullName({
                FirstName: row.FirstName,
                MiddleName: row.MiddleName,
                LastName: row.LastName,
                Render: false,
              }),
            },
          });
        }}
        eventSourceId={['form1823Task', 'patientClassification']}
        style={{
          border: isMobile,
          rowBorder: isMobile,
          columnPadding: isMobile,
        }}
        fields={field || patientFields}
        sort={sort || {}}
        columns={columns}
      />
    );
  } else {
    let columns = [];
    if (isMobile) {
      columns = [
        {
          render: ({row}) => {
            return renderPatientCard({row: row.patient});
          },
        },
      ];
    } else {
      columns = [
        {
          header: 'Name',
          type: 'text',
          render: ({row}) => {
            const {FirstName, LastName, MiddleName} = row.patient;
            return getPatientsFullName({FirstName, LastName, MiddleName});
          },
          width: 250,
        },
        {
          header: 'Residence',
          render: ({row}) => {
            const text =
              row?.patient?.residenceType?.value == Patient_Location_Type.Home
                ? Patient_Location_Type.Home
                : row?.patient?.residence?.name;
            return <TextRenderer value={text} />;
          },
          type: 'text',
          visible: !transitionalCareTab,
          width: 300,
        },
        {
          header: 'Current Location',
          render: ({row}) => {
            const text =
              row?.patient?.currentLocationType?.value ==
              Patient_Location_Type.Home
                ? Patient_Location_Type.Home
                : row?.patient?.currentLocation?.name;
            return <TextRenderer value={text} />;
          },
          type: 'text',
          visible: !transitionalCareTab,
          width: 300,
        },
        {
          header: 'Discharge Date',
          render: ({row}) => {
            return (
              <TextRenderer
                value={moment(row?.patient?.latestEncounter?.dischargeDate)
                  .utc()
                  .format(Config.dateFormat)}
              />
            );
          },
          visible: transitionalCareTab,
          width: 200,
        },
        {
          header: 'Readmission Risk',
          render: () => {
            return '';
          },
          visible: transitionalCareTab,
          width: 200,
        },
        {
          header: 'TCM Due Date',
          render: props => {
            return (
              <Row
                gap={36}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <TcmDueDate {...{row: props?.row?.patient}} />
              </Row>
            );
          },
          width: 150,
          visible: !admissionPlanningTab,
        },
        {
          header: 'Type',
          render: ({row}) => {
            return <TextRenderer value={Patient_Type[row?.patient?.type]} />;
          },
          visible: () => showPatientType,
          width: 150,
        },
        {
          render: ({row}) => {
            const activeTasks = row?.patient?.latestEncounter?.taskInfo?.filter(
              task => task.status === status.active,
            );
            if (
              activeTasks?.length &&
              row?.patient?.primaryPractice?._id === user?.practiceId?._id
            ) {
              return (
                <TaskWidget
                  patient={row?.patient}
                  encounter={row?.patient?.latestEncounter}
                  skipMessage
                />
              );
            }
          },
          width: 600,
          visible: () => {
            return user.practiceId && !admissionPlanningTab;
          },
        },
        {
          render: ({row}) => {
            return (
              <View
                style={{
                  ...patientDetailStyles.mr10,
                  ...patientDetailStyles.styles.row,
                  gap: 8,
                }}>
                <Button
                  key="Request Form 1823"
                  text="Request Form 1823"
                  onPress={() => requestForm1823Handler(row?.patientId)}
                  loading={loading}
                  buttonType={buttonType.linkButton}
                />
              </View>
            );
          },
          visible: () => {
            return (
              transitionalCareTab &&
              checkAuthorization(user, entity.form1823, action.request)
            );
          },
        },
        {
          render: ({row}) => {
            row?.patient?.residence?.type === FACILITY_TYPE.SkilledNursing &&
              RenderPatientTypeChangeAction(row?.patient);
          },
          visible: () => {
            return (
              showPatientType &&
              checkAuthorization(user, entity.patientType, action.edit)
            );
          },
          width: 50,
        },
      ];
    }
    return (
      <Table
        redoxKey={redoxKey}
        flex={false}
        api={api}
        filter={filter}
        eventSourceId={['form1823Task', 'patientClassification']}
        onRowPress={({row}) => {
          navigate(`/patient/details/${row?.patient?._id}/careTeamMembers`, {
            state: {
              patient: row.patient,
              titleName: row.patient.FullName,
            },
          });
        }}
        style={{
          border: isMobile,
          rowBorder: isMobile,
          columnPadding: isMobile,
        }}
        fields={
          field || {
            patient: patientFields,
          }
        }
        sort={sort || {}}
        columns={columns}
      />
    );
  }
};
