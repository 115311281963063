import {TCMEncounters} from '../components';
import {AddFacility} from '../components/AddRedoxFacility.modal';
import {CreateNewEncounter} from '../components/CreateNewEncounter.modal';
import {CreateNewTransitionalEvent} from '../components/createNewTransitionalEvent';
import {TaskRoutes} from './TaskRoutes';

export const EncounterRoutes = [
  {
    name: 'Encounter',
    path: 'tcmEncounter',
    component: TCMEncounters,
    children: [
      {
        name: 'Create New Encounter',
        path: 'create-new-encounter',
        component: CreateNewEncounter,
        screenOptions: {
          modalSize: 'medium',
        },
        children: [
          {
            name: 'Add New Facility From Create New Encounter',
            path: 'add-new-facility',
            component: AddFacility,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
      {
        name: 'Add Transitional Event',
        path: 'add-transitional-event',
        component: CreateNewTransitionalEvent,
        screenOptions: {
          modalSize: 'large',
        },
        children: [
          {
            name: 'Add New Facility From Create New Transitional Event',
            path: 'add-new-facility',
            component: AddFacility,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
      ...TaskRoutes,
    ],
  },
];
