import styled from 'styled-components';
import theme from '../theme/Theme';

const BreadCrumbContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  font-weight: 600;
  // white-space: nowrap;
  @media (max-width: 425px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

const BreadCrumbs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  font-weight: 600;
  // white-space: nowrap;
  @media (max-width: 425px) {
  }
`;
const BreadCrumbText = styled.text`
  color: ${theme.colors.SIDEBAR_BACKGROUND_LOW};
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;
export {BreadCrumbContainer, BreadCrumbText, BreadCrumbs};
