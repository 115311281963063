import React, {useEffect, useRef} from 'react';
import {Portal} from '@unthinkable/react-portal';
import {useIsMounted} from '@unthinkable/react-utils';
import {Styled, View} from '@unthinkable/react-core-components';
import {useNavigate} from 'react-router-dom';
import theme from '../../theme/Theme';
const StyledView = Styled(View)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex:1;
  flex-direction:column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.MODAL_BACKGROUND};
  z-index:10;
`;

const Modal = ({isVisible, hasBackdrop = true, style, children}) => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const onModalHide = function (e) {
    if (e.target == modalRef.current) {
      navigate(-1);
    }
  };
  const modalRef = useRef(null);

  useEffect(() => {
    if (isMounted() && !isVisible) {
      onModalHide?.();
    }
  }, [isVisible, isMounted, onModalHide]);

  if (!isVisible) {
    return null;
  }
  return (
    <Portal>
      <StyledView
        ref={modalRef}
        style={style}
        hasBackdrop={hasBackdrop}
        onPress={onModalHide}>
        {children}
      </StyledView>
    </Portal>
  );
};
export default Modal;
