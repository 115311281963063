import styled from 'styled-components';
// import theme from '../../../theme/Theme';

export const patientDetailStyles = {
  headerContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerContainerColumn: {
    flexDirection: 'column',
  },
  headerItemContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: 8,
  },
  headerItemContainerColumn: {
    flexDirection: 'column',
    gap: 8,
  },
  headerButtonContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8,
  },
  headerButtonContainerColumn: {
    flexDirection: 'column',
    gap: 8,
  },
  mr10: {
    marginRight: 10,
  },
  styles: {
    container: {
      flex: 1,
      flexDirection: 'column',
      overflowY: 'auto',
      gap: 18,
      margin: '14px',
    },
    row: {
      flexDirection: 'row',
      gap: 36,
    },
    column: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: '40px',
    },
  },
  viewStyles: {
    container: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      overflowX: 'hidden',
      margin: '-16px',
    },
    row: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      gap: 36,
    },
    column: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      gap: 36,
    },
    tableContainer: {
      flex: 1,
      overflow: 'auto',
      scrollbarWidth: 'none',
      gap: 8,
    },
    contentContainer: {
      flex: 1,
      overflow: 'auto',
      scrollbarWidth: 'none',
      gap: 18,
    },
  },
};


export const patientDetailsWidgetStyle = {
  flex: 1,
  margin: '12px',
  // height: '75vh',
};

export const patientDetailsWidgetStyleForMobile = {
  // maxHeight: '335px',
};

export const IconWrapper = styled.div`
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '28px',
  width: '28px',
  borderRadius: '6px',
  padding: '6px',
  backGroundColor: '#161E2D',
`;

export const PatientDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow-x: hidden;
  margin: -16px;
  @media (max-width: 425px) {
    padding: 10px;
    flex-direction: column;
  }
`;
