import theme from '../../../../theme/Theme';
export const patientTableStyles = {
  StatusContainer: {
    display: 'flex',
    borderRadius: 6,
    width: 75,
    height: 24,
    padding: '2px 8px 2px 8px',
    border: `1px solid ${theme.colors.SECONDARY_UPPER_LOW}`,
    boxShadow: theme.shadow.shadow6,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  GreenDot: {
    height: 8 /* Size of the dot */,
    width: 8 /* Size of the dot */,
    backgroundColor: theme.colors.GREEN_STATUS_COLOR /* Green color */,
    borderRadius: '50%' /* Makes the shape circular */,
    display: 'inline-block',
    alignSelf: 'center',
  },
  RedDot: {
    height: 8 /* Size of the dot */,
    width: 8 /* Size of the dot */,
    backgroundColor: theme.colors.RED_STATUS_COLOR /* Red color */,
    borderRadius: '50%' /* Makes the shape circular */,
    display: 'inline-block',
    alignSelf: 'center',
  },
  textContainer: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.colors.SIDEBAR_BACKGROUND_HIGH,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  actionContainer: {
    gap: 7,
  },
};
