import Regex from '../regex';

/**
 * Validates a name based on length constraints.
 *
 * @param {string} value - The name to be validated.
 * @returns {string|undefined} - Returns an error message if the name is invalid, otherwise undefined.
 */
export const nameValidation = value => {
  if (value && value.length < 4) return 'Name must be at least 4 characters';
  if (value && value.length > 100)
    return 'Name must be less than 100 characters';
  if (value && Regex.specialCharacterWithNumbers.test(value))
    return 'Name can not have special characters and numbers';
  return;
};

/**
 * Validates a phone number based on various formats.
 *
 * Valid formats include:
 * - 2124567890
 * - 212-456-7890
 * - (212)456-7890
 * - (212)-456-7890
 * - 212.456.7890
 * - 212 456 7890
 * - +12124567890
 * - +1 212-456-7890
 * - +1 212.456.7890
 * - +212-456-7890
 * - 1-212-456-7890
 *
 * @param {string} value - The phone number to be validated.
 * @param {string} type - The type (phone or fax) being validated.
 * @returns {string|undefined} - Returns an error message if the phone number is invalid, otherwise undefined.
 */
export const phoneAndFaxValidation = (value, type) => {
  const pattern = Regex.phoneAndFax;
  if (value && !pattern.test(value))
    return `Please enter a valid ${type} number`;
  return;
};

export const shortNameValidation = value => {
  if (value && value.length !== 4)
    return 'Short Name must be of 4 characters only';
  if (value && Regex.specialCharacterWithNumbers.test(value))
    return 'Short Name can not have special characters or numbers';
  return;
};

export const facilityShortNameValidation = value => {
  if (value && Regex.specialCharacters.test(value))
    return 'Short Name can not have special characters';
  return;
};

export const facilityNameValidation = value => {
  if (value && value.length < 4) return 'Name must be at least 4 characters';
  if (value && value.length > 100)
    return 'Name must be less than 100 characters';
  if (value && Regex.containNumber.test(value))
    return 'Name can not have numbers';
  return;
};

export const validateDate = (date, lowerLimit) => {
  const todayDate = new Date().setHours(12, 0, 0, 0);
  const lowerLimitDate = new Date(lowerLimit).setHours(12, 0, 0, 0);
  const selectDate = new Date(date).setHours(12, 0, 0, 0);

  if (selectDate > todayDate) {
    return 'you can not set future Date';
  }
  if (lowerLimitDate > selectDate) {
    return 'you can not set date earlier than discharge date';
  }
};

export const IdentifierValidation = value => {
  if (value && Regex.specialCharacters.test(value))
    return 'Identifiers can not have special characters';
  return;
};
