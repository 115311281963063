export const generateCategoryWiseCareProviders = data => {
  const dataMap = {
    'Home Health': [],
    'Primary Care': [],
    Specialist: [],
  };
  data?.forEach(item => {
    if (item?.facilityId) {
      if (!dataMap[`${item.facilityId.name} users`]) {
        dataMap[`${item.facilityId.name} users`] = [item];
      } else dataMap[`${item.facilityId.name} users`].push(item);
    } else {
      item?.organizationType?.forEach(type => {
        dataMap[type].push(item);
      });
    }
  });
  const finalData = [
    {
      name: 'Header',
      expanded: true,
    },
  ];
  Object.keys(dataMap)?.forEach(key => {
    if (dataMap[key].length) {
      const obj = {
        title: key,
        data: dataMap[key],
        expanded: true,
      };
      finalData.push(obj);
    }
  });
  return finalData;
};

export const generateUserTypeWiseCareTeamMembers = data => {
  const dataMap = {};
  data?.forEach(item => {
    if (!dataMap[item?.user?.userType?.name]) {
      dataMap[item?.user?.userType?.name] = [item];
    } else if (item?.user?.userType?.name) {
      dataMap[item?.user?.userType?.name].push(item);
    }
  });
  const finalData = [
    {
      name: 'Header',
      expanded: true,
    },
  ];
  Object.keys(dataMap).forEach(key => {
    const obj = {
      name: key,
      data: dataMap[key],
      expanded: true,
    };
    finalData.push(obj);
  });
  return finalData;
};
