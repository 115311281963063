import {AddContactAttemptsModal} from '../patient/components/AddContactAttempts.modal';
import {CompleteVisitTask} from '../patient/components/CompleteTCMVisit.modal';
import {AddMedicineModal} from '../patient/components/addMedicine.modal';
import {AddMedication} from '../patient/screens/AddMedication';
import {PatientDetails} from '../patient/screens/PatientDetails';
import {TaskTable} from './screens/TaskTable';
import CompleteMedication from '../patient/components/CompleteMedication';
import {TcmNotesModal} from '../patient/components/TcmNotes.modal';
import {Form1823} from './screens/Form1823';
import {FormRejectReason} from './screens/formRejectReason';
import {FacilityAssignmentModal} from './screens/facilityAssignment.modal';
import {InitialEligibilityModal} from './screens/InitialEligibility.modal';
import {AddFacility} from '../patient/components/AddRedoxFacility.modal';
import CompleteMedicationModal from '../patient/components/CompleteMedication.modal';
import {EncounterRoutes} from '../patient/routes/TcmEncounterRoutes';
import {EditFacility} from '../facilities/screens/FacilityForm';
import {completePatientClassificationTask} from '../patient/components/CompletePatientClassificationTask.modal';
import {completeDischargeStatusTask} from '../patient/components/CompleteDischargeStatus.modal';
const TaskRoutes = [
  {
    name: 'Tasks',
    path: '/tasks',
    component: TaskTable,
    children: [
      {
        name: 'Complete Visit',
        path: '/tasks/complete-visit/:taskId',
        component: CompleteVisitTask,
      },
      {
        name: 'Add Contact Attempts',
        path: '/tasks/:EncounterId/:TaskId/add-contact-attempts',
        component: AddContactAttemptsModal,
        screenOptions: {
          modalSize: 'large',
        },
      },
      {
        name: 'TCM Notes',
        path: '/tasks/tcm-notes',
        component: TcmNotesModal,
        screenOptions: {
          modalSize: 'xlarge',
        },
      },
      {
        name: 'Facility Assignment',
        path: '/tasks/facility-assignment',
        component: FacilityAssignmentModal,
        screenOptions: {
          modalSize: 'medium',
        },
        children: [
          {
            name: 'Add New Redox Facility From Facility Assignment',
            path: 'add-new-facility',
            component: AddFacility,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
      {
        name: 'Initial Eligibility',
        path: '/tasks/initial-eligibility',
        component: InitialEligibilityModal,
        screenOptions: {
          modalSize: 'medium',
        },
        children: [
          {
            name: 'Add New Redox Facility From Initial Eligibility',
            path: '/tasks/initial-eligibility/add-new-redox-facility',
            component: AddFacility,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
      {
        name: 'Patient Classification',
        path: '/tasks/patient-classification/:tcmTaskId',
        component: completePatientClassificationTask,
        screenOptions: {
          modalSize: 'medium',
        },
      },
      {
        name: 'Complete Discharge Status Task',
        path: '/tasks/complete-discharge-status/:taskId',
        component: completeDischargeStatusTask,
        screenOptions: {
          modalSize: 'medium',
        },
        children: [
          {
            name: 'Add New Redox Facility',
            path: 'add-new-facility',
            component: AddFacility,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
    ],
  },
  {
    name: 'Facility Onboarding',
    path: '/tasks/facility-onboarding/:facilityId',
    component: EditFacility,
  },
  {
    name: 'Patient Details',
    path: '/tasks/patient/details',
    component: PatientDetails,
    type: 'stack',
    outlet: false,
    children: [...EncounterRoutes],
  },
  {
    name: 'Medication Task',
    path: '/tasks/medication-task',
    component: CompleteMedication,
    children: [
      {
        name: 'Complete Medication',
        path: 'complete-medication/:taskId',
        component: CompleteMedicationModal,
      },
    ],
  },
  {
    name: 'Add Medication',
    path: '/tasks/medication-task/add-medication',
    component: AddMedication,
    children: [
      {
        name: 'Add Medicine',
        path: '/tasks/medication-task/add-medication/medicine',
        component: AddMedicineModal,
      },
    ],
  },
  {
    name: 'Form 1823',
    path: '/tasks/form1823/:taskId',
    component: Form1823,
    children: [
      {
        name: 'Reject Reason',
        path: 'reject-reason',
        component: FormRejectReason,
        screenOptions: {
          modalSize: 'small',
        },
      },
    ],
  },
];

export default {
  stack: TaskRoutes,
};
