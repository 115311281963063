import checkAuthorization from '../../services/accessService';
import {action, entity} from '../../constant/authorization';
import {Dashboard} from './screens/Dashborad';
import {AddFacility} from '../patient/components/AddRedoxFacility.modal';
import {TcmNotesModal} from '../patient/components/TcmNotes.modal';
import {AddContactAttemptsModal} from '../patient/components/AddContactAttempts.modal';
import {AddMedication} from '../patient/screens/AddMedication';
import {AddMedicineModal} from '../patient/components/addMedicine.modal';
import {CompleteVisitTask} from '../patient/components/CompleteTCMVisit.modal';
import CompleteMedication from '../patient/components/CompleteMedication';
import CompleteMedicationModal from '../patient/components/CompleteMedication.modal';
import {Form1823} from '../tasks/screens/Form1823';
import {FormRejectReason} from '../tasks/screens/formRejectReason';
import {InitialEligibilityModal} from '../tasks/screens/InitialEligibility.modal';
import {FacilityAssignmentModal} from '../tasks/screens/facilityAssignment.modal';
import {EditFacility} from '../facilities/screens/FacilityForm';
import {completePatientClassificationTask} from '../patient/components/CompletePatientClassificationTask.modal';
import {completeDischargeStatusTask} from '../patient/components/CompleteDischargeStatus.modal';

const stackRoutes = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
    children: [
      {
        name: 'Complete Visit',
        path: 'complete-visit/:taskId',
        component: CompleteVisitTask,
      },
      {
        name: 'Add Contact Attempts',
        path: ':EncounterId/:TaskId/add-contact-attempts',
        component: AddContactAttemptsModal,
        screenOptions: {
          modalSize: 'large',
        },
      },
      {
        name: 'Complete Discharge Status Task',
        path: 'complete-discharge-status/:taskId',
        component: completeDischargeStatusTask,
        children: [
          {
            name: 'Add New Redox Facility',
            path: 'add-new-facility',
            component: AddFacility,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
      {
        name: 'TCM Notes',
        path: 'tcm-notes',
        component: TcmNotesModal,
        screenOptions: {
          modalSize: 'xlarge',
        },
      },
      {
        name: 'Facility Assignment',
        path: 'facility-assignment',
        component: FacilityAssignmentModal,
        screenOptions: {
          modalSize: 'medium',
        },
        children: [
          {
            name: 'Add New Redox Facility From Facility Assignment',
            path: 'add-new-facility',
            component: AddFacility,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
      {
        name: 'Initial Eligibility',
        path: 'initial-eligibility',
        component: InitialEligibilityModal,
        screenOptions: {
          modalSize: 'medium',
        },
        children: [
          {
            name: 'Add New Redox Facility From Initial Eligibility',
            path: 'add-new-redox-facility',
            component: AddFacility,
            screenOptions: {
              modalSize: 'medium',
            },
          },
        ],
      },
      {
        name: 'Patient Classification',
        path: 'patient-classification/:tcmTaskId',
        component: completePatientClassificationTask,
      },
    ],
    visible: user => {
      if (user) {
        return checkAuthorization(user, entity.dashboard, action.view);
      }
      return true;
    },
  },
  {
    name: 'Medication Task',
    path: '/dashboard/medication-task',
    component: CompleteMedication,
    children: [
      {
        name: 'Complete Medication',
        path: 'complete-medication/:taskId',
        component: CompleteMedicationModal,
      },
    ],
  },
  {
    name: 'Add Medication',
    path: '/dashboard/medication-task/add-medication',
    component: AddMedication,
    children: [
      {
        name: 'Add Medicine',
        path: '/dashboard/medication-task/add-medication/medicine',
        component: AddMedicineModal,
      },
    ],
  },
  {
    name: 'Form 1823',
    path: '/dashboard/form1823/:taskId',
    component: Form1823,
    children: [
      {
        name: 'Reject Reason',
        path: 'reject-reason',
        component: FormRejectReason,
        screenOptions: {
          modalSize: 'small',
        },
      },
    ],
  },
  {
    name: 'Facility OnBoarding',
    path: '/dashboard/facility-onboarding/:facilityId',
    component: EditFacility,
  },
];

export default {
  stack: stackRoutes,
};
